import React, { useEffect, useState } from 'react'
import {
    TableCell,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import { useSelector } from "react-redux";
const Header = ({entity,entities}) => {
  const [headers, setHeaders] = useState([]);
  const listData = useSelector((state) => state.layout?.columns)
  const show = listData?.Admin?.filter((a)=>a[entity]).flatMap((A) => A[entity]?.Columns?.map(a => a.list))
  const type = listData?.Admin?.filter((a)=>a[entity]).flatMap((A) => A[entity]?.Columns?.map(a => a.type))
  const keys = listData?.Admin?.filter((a)=>a[entity]).flatMap((A) => A[entity]?.Columns?.map(a => a.key))
  const heads =listData?.Admin?.filter(hd=>hd[entity])?.flatMap((header)=> header[entity]?.Columns?.map(head => head?.name))
useEffect(() => {
  if (listData){
  setHeaders(heads)
  }
}, [listData])
  function renderAdminTable(header,type, index,listing,key,head) {
    const oldIndex = headers?.indexOf(head)
    if(listing)
    switch (type) {
      case 'checklist':
        const protocols = entities?.map(entity => entity?.[key])?.flatMap(e => e? Object.keys(e): [])
        const protocolsHeaders = protocols?.filter((item,index)=> protocols?.indexOf(item) === index)
        const protocolsHeadCleaned = protocolsHeaders?.map(header => header[0].toUpperCase() + header?.split('_')[0].slice(1))
        return (
          protocolsHeadCleaned?.map((a,i) => (
          <TableCell 
          key = {a}
          className="bg-color-secondary text-white pt-2"
          style={{ textAlign: "center" }}
        >
          {a}
        </TableCell>
        )));
      default:
        return (<TableCell 
          key = {header}
          className="bg-color-secondary text-white pt-2"
          style={{ textAlign: "center" }}
        >
          {header}
        </TableCell>); 
    }
  }
  return (
    <TableHead>
      <TableRow>
      {heads?.flatMap( (header,index) =>(
        show[index]&&
        renderAdminTable(header,type[index],index,show[index],keys[index],heads[index])
      ))}
    </TableRow>
  </TableHead>
  )
}
export default Header