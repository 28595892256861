import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Card,
  Button,
  FormControl
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import PerfectScrollbar from "react-perfect-scrollbar";
import client from "api/apiAuth/guestClient";
import { apiConfig } from "api/utilities";
import { TextField } from "@mui/material";

function AttachmentComment({ openCommentModal, setOpenCommentModal, comment,attachment,currentUser }) {

  const [modifiedAttachment, setModifiedAttachment] = useState(attachment)
  const [submitFlag, setSubmitFlag] = useState(false);
  console.log(currentUser)
  const handleCancel = ()=>{
    setOpenCommentModal(false)
    formik.resetForm()
    setSubmitFlag(false);
  }

  const formik = useFormik({
    initialValues: {modifiedAttachment},
    enableReinitialize:true,
    onSubmit: async() =>{
       await client.post(`/attachment/updateAttachment?attachment_id=${attachment.id}&attachmentInstance=${JSON.stringify(modifiedAttachment)}`);
      setOpenCommentModal(false)
      setSubmitFlag(false);
      formik.resetForm()
    }
    
  });
  const onEditInputChange = (ev) =>  {
    let entity = "";
  entity = ev.target.value
  setModifiedAttachment(modifiedAttachment => ({
      ...modifiedAttachment,
      comment:entity
    }));
    setSubmitFlag(true);
  }
  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="md"
      open={openCommentModal}
      onClose={() => setOpenCommentModal(false)}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "60%",
        margin: "auto",
        height: "120%",
      }}
    >
      <DialogTitle>
        <span className=" font-weight-bold titleModal">{` ملاحظات ${attachment?.document?.name_en}`}</span>
      </DialogTitle>
      <DialogContent>
        <Grid style={{ padding: "0px", justifyContent: "flex-start" }} item xs={12}>
          {/* <Card className="card-box bg-secondary"> */}
          {/* <form onSubmit={formik.handleSubmit}> */}

                <Card
                  className="font-weight-bold font-size-lg mb-1 text-black text-center rounded-1"
                  raised
                  style={{padding:"10px", overflowWrap: "break-word"}}

                  // onChange={(ev)=> {onEditInputChange(ev)}}
                >
                  {formik.values.modifiedAttachment.comment ? formik.values.modifiedAttachment.comment : `No Comment` }
                </Card>

            {/* <Button
              xs={12}
              className={`saveBtn primary-key`}
              type="submit"
              disabled= {!submitFlag}
            >
              Save
            </Button>
            <Button
              xs={12}
              className={`CancelBtn secondary-key`}
              onClick={handleCancel}
            >
              {" Cancel "}
            </Button>
          </form> */}
          {/* <div className="px-3 pb-3">
              <div className="scroll-area rounded bg-white shadow-overflow">
                <PerfectScrollbar>
                  <div className="p-3">
                    {files.length > 0 &&
                      files.map((file, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="">
                                  <p className="text-black">
                                    {file?.user?.username || "Missing User"}
                                  </p>
                                </div>
                                <small className="d-flex  align-items-center">
                                  <span className="pl-2 ">
                                    {new Date(
                                      file.createdAt
                                    ).toLocaleDateString("en-GB")}
                                  </span>
                                </small>
                              </div>
                              <div>
                                <a
                                  href={`${apiConfig.baseUrl}attachment/download?id=${file.id}&log=true`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button
                                    disableFocusRipple
                                    disableRipple
                                    className="btn btn-outline-primary btn-transition-none d-inline "
                                  >
                                    view file
                                  </Button>
                                </a>
                              </div>
                            </div>
                            <div className="divider my-3" />
                          </React.Fragment>
                        );
                      })}
                  </div>
                </PerfectScrollbar>
              </div>
            </div> */}
          {/* </Card> */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AttachmentComment;
