/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import ColumnsRepresentation from "./ColumnsRepresentation";
import {
  Table,
  Card,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import SpinnerLoader from "../../components/SpinnerLoader";
import { getValue } from "utils/GetObjectValues";
import TableToolbar from "./TableToolbar";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import WorklistFilter from "components/WorklistFilter";
import { applyConstraintsOnActions } from "./utils";
let draftsTab = [];
try {
  draftsTab = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/draftsTab`);
} catch (err) {}

function WorklistTable(props) {
  let {
    layoutStructure,
    layoutData,
    currentTab,
    setPage,
    tasksCount,
    loading,
    user,
    setSubmitTaskLoader,
    page,
    filterColumns,
    redirectKey,
    formId,
    submissionAction,
    checkedSubmissions,
    setCheckedSubmissions,
    group,
    propsFunctions,
    groupIngTab,
    runActionFlag,
    setRunActionFlag,
    getDataCb,
  } = props;
  let history = useHistory();
  const [tableLayout, setTableLayout] = useState([]);
  const [checkTab, setCheckTab] = useState(null);
  const [filterLayout, setFilterLayout] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const redirectInstance = (instance) => {
    let redirectUrl;
    const draftTab = draftsTab.find((el) => el.id == currentTab);
    if (draftTab) {
      let { form_id, key } = draftTab;
      history.push(`/programs/${instance[form_id]}/draft/${instance[key]}`);
    } else if (groupIngTab) {
      history.push(
        `/SubmissionGroupDetails/Components/GroupDetails/${instance.id}`
      );
    } else {
      if (redirectKey) {
        redirectUrl = getValue(instance, redirectKey);
        let taskId = getValue(instance, "taskId");
        history.push(`/details/${redirectUrl}/${taskId}`);
      } else {
        history.push(`/details/${instance.id}`);
      }
    }
  };
  const extractRowData = (row, rowIndex) => {
    return tableLayout.map((column, index) => {
      let color;
      let value = "";
      if (column.mergedColumn) {
        column.keys.forEach((element, index) => {
          value += getValue(row, element.key);
          if (index + 1 < column.keys.length) {
            value += " - ";
          }
        });
        row[column.key] = value;
      } else {
        row[column.key] = getValue(row, column.key);
      }
      value = row[column.key];
      const styleClass =
        column.styleClass || getValue(row, column.styleClassKey);
      column = applyConstraintsOnActions(column, row);
      return (
        <ColumnsRepresentation
          rowsCount={layoutData.length}
          key={index}
          cellType={column.inputLayout.type}
          rowIndex={rowIndex}
          index={index}
          value={value}
          color={color}
          history={history}
          row={row}
          setSubmitTaskLoader={setSubmitTaskLoader}
          column={column}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setSelectedCheckbox={setSelectedCheckbox}
          selectedCheckbox={selectedCheckbox}
          currentTab={currentTab}
          styleClass={styleClass}
          submissionAction={submissionAction}
          setCheckedSubmissions={setCheckedSubmissions}
          checkedSubmissions={checkedSubmissions}
          group={group}
          propsFunctions={propsFunctions}
          runActionFlag={runActionFlag}
          setRunActionFlag={setRunActionFlag}
          setCheckTab={setCheckTab}
        />
      );
    });
  };
  // const tableData =

  //   );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };
  //sorting
  // let [sortUp, setSortUp] = useState(false);
  // let [sortingColumn, setSortingColumn] = useState();

  // const handleSorting = (key, association, forceKey) => {
  //   setSortUp(!sortUp);
  //   setSortingColumn(key);
  //   let sortingType = association + "Sorting";
  //   let sortObj = { orderBy: key, type: sortingType };
  //   if (sortUp) sortObj = { ...sortObj, order: "DESC" };
  //   else sortObj = { ...sortObj, order: "ASC" };
  //   if (forceKey) sortObj.forceKey = true;
  //   setSort(sortObj);
  // };
  useEffect(() => {
    if (!_.isEmpty(layoutStructure)) {
      let layout = [];
      let filter = [];

      if (layoutStructure["Columns"]) {
        layout = layoutStructure["Columns"];
      } else {
        layoutStructure[currentTab]?.["Columns"]["shared"] &&
          layout.push(...layoutStructure[currentTab]["Columns"]["shared"]);
        if (user) {
          user.roles.forEach((role) => {
            if (
              !layout.some((item) => {
                const tabsKeys = layoutStructure[currentTab]?.["Columns"][
                  role["name"]
                ].map((item) => item.key);
                return tabsKeys.includes(item.key);
              })
            ) {
              layoutStructure[currentTab]?.["Columns"][role["name"]] &&
                layout.push(
                  ...layoutStructure[currentTab]["Columns"][role["name"]]
                );
            }
            if (user.roles[0].name == "User") {
              const indexOfCompanyCoumn = layout.findIndex(
                (obj) => obj.name == "Company"
              );
              if (indexOfCompanyCoumn > 0) {
                layout.splice(indexOfCompanyCoumn, 1);
              }
            }
          });
          if (
            filterColumns &&
            typeof filterColumns === "object" &&
            !Array.isArray(filterColumns)
          ) {
            user.roles.forEach((role) => {
              filter.push(filterColumns[role["name"]]);
            });
          }
        }
      }
      filterColumns &&
        Object.keys(filterColumns).length > 0 &&
        filter.push(...filterColumns["shared"]);
      setFilterLayout(filter);
      layout.sort((a, b) => a.order - b.order);

      setTableLayout(layout);
    }
  }, [layoutStructure, layoutData, currentTab, filterColumns]);

  useEffect(() => {
    if (layoutData)
      setSelectedCheckbox(new Array(layoutData.length).fill(false));
  }, [layoutData]);

  useEffect(() => {
    setPage(0);
    setSelectedRows([]);
  }, [currentTab]);
  return (
    <>
      <Card
        className="card-box mb-spacing-6-x2"
        style={{
          borderRadius: "0 20 20px 20px",
          boxShadow: "none",
          border: "none",
          marginTop: "-4px",
        }}
      >
        {filterColumns && (
          <WorklistFilter
            filterLayout={filterLayout}
            currentTab={currentTab}
            formId={formId}
          />
        )}
        <div className="worklist-table-container">
          {loading ? (
            <>
              <SpinnerLoader />
            </>
          ) : (
            <>
              {selectedRows.length > 0 && (
                <TableToolbar
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  history={history}
                  layoutData={layoutData}
                  setSubmitTaskLoader={setSubmitTaskLoader}
                  currentTab={currentTab}
                  user={user}
                  checkTab={checkTab}
                  getDataCb={getDataCb}
                />
              )}
              <PerfectScrollbar
                options={{
                  suppressScrollY: true,
                  useBothWheelAxes: true,
                }}
                className="displayScroll"
              >
                <Table className="table table-alternate-spaced text-nowrap mb-0 tabelStyle">
                  <TableHead className="font-size-sm">
                    <TableRow>
                      {tableLayout.map((column) => {
                        return (
                          <TableCell
                            className="text-center bg-secondary pt-2"
                            key={column.key}
                            style={column.style || {}}
                          >
                            {column.name}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {layoutData && layoutData.length > 0 ? (
                      layoutData.map((row, index) => {
                        const remainingNumber =
                          row.remaining?.number?.match(/\d+/g);

                        return (
                          <TableRow
                            className={`${
                              remainingNumber && remainingNumber[0] <= 1
                                ? "dangerRow"
                                : ""
                            } ${row.rowClass?.class}`}
                            key={index}
                            onClick={(e) => {
                              group
                                ? e.stopPropagation()
                                : redirectInstance(row);
                            }}
                          >
                            {extractRowData({ ...row }, index)}
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan="9">
                          <h5 className="m-top">No results</h5>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </>
          )}
        </div>
        <div
          className="p-4 d-flex "
          style={{ justifyContent: "flex-end", borderRadius: "20px" }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tasksCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={""}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Card>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.list.loading,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, null)(WorklistTable);
