import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { ListAlt } from "@material-ui/icons";
import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";
import NotificationsIcon from "@material-ui/icons/Notifications";
const NoteIcon = createSvgIcon(
  <path
    d="M5.754 22.005a2.25 2.25 0 0 1-2.25-2.25L3.502 5.75a2.25 2.25 0 0 1 2.25-2.25l.747-.001l.001-.749a.75.75 0 0 1 .648-.743L7.25 2a.75.75 0 0 1 .743.648L8 2.75v.749H11V2.75a.75.75 0 0 1 .65-.743l.1-.007a.75.75 0 0 1 .744.648l.007.102v.749h2.997l.001-.749a.75.75 0 0 1 .648-.743L16.25 2a.75.75 0 0 1 .743.648L17 2.75v.749h.749a2.25 2.25 0 0 1 2.25 2.25l.001 11c0 .06-.007.12-.02.176l-.03.094l-.045.096l-.02.032a.685.685 0 0 1-.105.133l-4.504 4.505a.74.74 0 0 1-.085.073l.085-.073a.744.744 0 0 1-.105.087l-.088.052l-.116.047l-.114.026l-.053.006l-.055.002H5.754zM17.748 5H5.742a.75.75 0 0 0-.74.75l.002 14.004c0 .415.336.75.75.75h8.24l.001-2.254a2.25 2.25 0 0 1 2.096-2.245l.154-.005H18.5V5.75a.75.75 0 0 0-.75-.75zm-.31 12.5h-1.193a.75.75 0 0 0-.743.648l-.007.102v1.194l1.943-1.944zM7.25 16h3.998a.75.75 0 0 1 .102 1.493l-.102.007H7.25a.75.75 0 0 1-.102-1.493L7.25 16h3.998H7.25zm0-4h8.998a.75.75 0 0 1 .102 1.493l-.102.007H7.25a.75.75 0 0 1-.102-1.493L7.25 12h8.998H7.25zm0-4h8.998a.75.75 0 0 1 .102 1.493l-.102.007H7.25a.75.75 0 0 1-.102-1.493L7.25 8h8.998H7.25z"
    fill="currentColor"
  />,
  "NoteIcon"
);

export const routes = [
  {
    key: "My Worklist",
    exact: true,
    path: "/workList",
    icon: <NoteIcon className="mr-1" />,
  },
  {
    key: "My CPMP Worklist",
    exact: true,
    path: "/worklist/:form_id",
    icon: <NoteIcon className="mr-1" />,
  },
  {
    key: "Admin Panel",
    exact: true,
    path: "/admin/users",
    icon: <AssignmentIndIcon className="mr-1" />,
  },
  {
    exact: true,
    key: "New Request",
    path: "/programs",
    icon: <LibraryBooksTwoToneIcon className="mr-1" />,
  },
  {
    exact: true,
    key: "Notifications",
    path: "/notifications",
    icon: <NotificationsIcon className="mr-1" />,
  },
  {
    exact: true,
    key: "Approval Request",
    path: "/programs/:id",
    icon: <ListAlt className="mr-1" />,
  },
  {
    exact: true,
    path: "/details/:id",
  },
  {
    exact: true,
    key: "Request Details",
    path: "/details/:id/:taskId",
  },
];
