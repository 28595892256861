import {
  HomeOutlined,
  ListAlt,
  PeopleAlt,
  Drafts,
  Notifications,
} from "@material-ui/icons";

import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";
import * as iconss from "./assets/customSvgMuiIcons";

export const sideMenuItems = (labels, urls, icons) => {
  const iconz = { ...iconss };
  return {
    admin: [
      {
        links: labels && [
          ...labels?.map((item, index) => ({
            key: index,
            label: item,
            url: urls[index],
            Icon: iconz?.[icons?.[index]]
              ? iconz?.[icons?.[index]]
              : iconz.Default,
          })),
          {
            label: "Dashboard",
            url: "/dashboard",
            Icon: HomeOutlined,
          },
        ],
        main: "Admin",
      },
    ],
    Hospital: [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: HomeOutlined,
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: ListAlt,
          },
          {
            label: "Notifications",
            url: "/notifications",
            Icon: Notifications,
          },
        ],
        main: "Navigation",
      },
      {
        links: [
          {
            label: "New Request",
            url: "/claims",
            Icon: LibraryBooksTwoToneIcon,
          },
          {
            label: "Drafts/Emails",
            url: "/drafts",
            Icon: Drafts,
          }
        ],
        main: "Requests",
      },
    ],
    "Pre approval reviewer": [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: HomeOutlined,
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: ListAlt,
          },
          {
            label: "Inquiry",
            url: "/claims",
            Icon: LibraryBooksTwoToneIcon,
          },
          {
            label: "Notifications",
            url: "/notifications",
            Icon: Notifications,
          },
        ],
        main: "Navigation",
      },
    ],
    "Patient Affairs manager": [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: HomeOutlined,
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: ListAlt,
          },
          {
            label: "Inquiry",
            url: "/claims",
            Icon: LibraryBooksTwoToneIcon,
          },
        ],
        main: "Navigation",
      },
    ],
    "Beneficiary Affairs manger": [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: HomeOutlined,
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: ListAlt,
          },
          {
            label: "Inquiry",
            url: "/claims",
            Icon: LibraryBooksTwoToneIcon,
          },
          // {
          //   label: "Notifications",
          //   url: "/notifications",
          //   Icon: Notifications,
          // },
        ],
        main: "Navigation",
      },
    ],
    "C A B D Reviewer": [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: HomeOutlined,
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: ListAlt,
          },
          {
            label: "Inquiry",
            url: "/claims",
            Icon: LibraryBooksTwoToneIcon,
          },
          // {
          //   label: "Notifications",
          //   url: "/notifications",
          //   Icon: Notifications,
          // },
        ],
        main: "Navigation",
      },
    ],
    "Committe Member": [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: HomeOutlined,
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: ListAlt,
          },
          {
            label: "Inquiry",
            url: "/claims",
            Icon: LibraryBooksTwoToneIcon,
          },
          // {
          //   label: "Notifications",
          //   url: "/notifications",
          //   Icon: Notifications,
          // },
        ],
        main: "Navigation",
      },
    ],
    "Administrator of C A B D": [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: HomeOutlined,
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: ListAlt,
          },
          {
            label: "Inquiry",
            url: "/claims",
            Icon: LibraryBooksTwoToneIcon,
          },
          // {
          //   label: "Notifications",
          //   url: "/notifications",
          //   Icon: Notifications,
          // },
        ],
        main: "Navigation",
      },
    ],
    "Head of C A B D (Signer)": [
      {
        links: [
          {
            label: "Home",
            url: "/",
            Icon: HomeOutlined,
          },
          {
            label: "Worklist",
            url: "/worklist",
            Icon: ListAlt,
          },
          {
            label: "Inquiry",
            url: "/claims",
            Icon: LibraryBooksTwoToneIcon,
          },
          // {
          //   label: "Notifications",
          //   url: "/notifications",
          //   Icon: Notifications,
          // },
        ],
        main: "Navigation",
      },
    ],
  };
};
