/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Menu,
  MenuItem,
} from "@material-ui/core";
import "./index.scss";
import SearchBar from "components/SearchBars";
import Table from "components/Table";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToInitialValues } from "reducers/form/formActions";
import { fetchForms } from "reducers/list";
import {
  FILE_CODE_REGEX,
  LAYOUT_STRUCTURE,
  NATIONAL_ID_REGEX,
} from "./constants";
import imageSrc from "assets/images/OBJECTS.svg";
import { fetchSubmissions, getPatient } from "./api";
import { useCallback } from "react";
import TableTabs from "./TableTabs";

function Inquiry({
  addToInitialValues,
  fetchForms,
  forms,
  getOldSubmission,
  user,
}) {
  const [data, setData] = useState({});
  const [layoutStructure, setLayoutStructure] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchValue, setSearchValue] = useState();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewRequest = (formId) => {
    if (data?.rows[0]) {
      getPatientData(searchValue);
    }
    history.push(`/programs/${formId}`);
    handleClose();
  };

  const handleRenewRequest = useCallback((row) => {
    const { form_id, id } = row;
    history.push(`/programs/${form_id}/renew/${id}`);
  }, []);

  const validateSearchBarValue = function (value) {
    return NATIONAL_ID_REGEX.test(value) || FILE_CODE_REGEX.test(value);
  };

  const getSubmissions = useCallback(async function (
    value,
    currentTab = 0,
    limit = 10,
    page = 0
  ) {
    const include = [
      { association: "checklist" },
      { association: "patient",  
      include: [
    {
      required:true,
      association: "patientProfile",
    },
  ], },
      { association: "breadwinner" },
    ];
    const tabFilters = LAYOUT_STRUCTURE[currentTab].Filters;

    const filters = [
      {
        operator: "or",

        value: [
          { field: "$patient.national_id$", value },
          {
            field: "$breadwinner.national_id$",
            value,
          },
          {
            field: "$file_code$",
            value,
          },
        ],
      },
      ...tabFilters,
    ];
    if (value) {
      const { data } = await fetchSubmissions(include, limit, page, filters);
      setData(data);
    }
  },
  []);

  const getPatientData = useCallback(async function (id) {
    if (id) {
      const { data } = await getPatient(id);
      addToInitialValues("patient", data);
    }
  }, []);

  const getData = useCallback(
    async function (value) {
      setCurrentTab(0);
      await getSubmissions(value, 0, limit, page);
    },
    [currentTab, limit, page]
  );

  useEffect(() => {
    fetchForms({
      model: "Form",
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    setLayoutStructure(LAYOUT_STRUCTURE);
    getSubmissions(searchValue, currentTab, limit, page);
  }, [limit, page, currentTab]);

  return (
    <Grid container direction="row" justifyContent="center" align="center">
      <Grid xs={12} item>
        <h4>ملف الموافقات المسبقة للمريض</h4>
      </Grid>
      <Grid xs={12} item>
        <span className="spanText">يجب عليك البحث بالرقم القومي </span>
      </Grid>
      <Grid xs={10} item className="my-2">
        <SearchBar
          isValid={validateSearchBarValue}
          searchCB={getData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Grid>
      <Grid xs={12} item>
        {data.rows ? (
          <Card className="d-block  text-center card-box p-4 align-items-center justify-content-between flex-row">
            <TableTabs
              menus={Object.values(LAYOUT_STRUCTURE)}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
            <div className="card-header">
              <div className="card-header--title">
                <span className="float-left font-size-small spanText">
                  تحتوى هذه القائمة على الملفلات السابقه للمريض او عائل الاسرة
                </span>
              </div>

              {user.roles.some((role) => role.name === "Hospital") && (
                <div className="card-header--actions">
                  <Button
                    size="medium"
                    color="primary"
                    variant="outlined"
                    onClick={handleClick}
                  >
                    <span className="btn-wrapper--label">عمل طلب جديد</span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="opacity-8 font-size-xs"
                      />
                    </span>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    classes={{ list: "p-0" }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <div className="p-3">
                      {forms?.map((form) => (
                        <MenuItem
                          key={form.id}
                          className="pr-5 px-3 text-primary"
                          onClick={() => handleNewRequest(form.id)}
                        >
                          {form.name}
                        </MenuItem>
                      ))}
                    </div>
                  </Menu>
                </div>
              )}
            </div>
            <CardContent>
              <div className="d-block  align-items-center">
                <Table
                  className="mt-4 w-100"
                  layoutStructure={layoutStructure}
                  setLayoutStructure={setLayoutStructure}
                  currentTab={currentTab}
                  layoutData={data?.rows}
                  setPage={setPage}
                  setLimit={setLimit}
                  limit={limit}
                  tasksCount={data?.count || 0}
                  page={page}
                  propsFunctions={{ handleRenewRequest }}
                ></Table>
              </div>
            </CardContent>
          </Card>
        ) : (
          <img src={imageSrc} alt="There are no results" />
        )}
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    forms: state.formList.forms,
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  addToInitialValues: (key, value) => dispatch(addToInitialValues(key, value)),
  fetchForms: (params) => dispatch(fetchForms(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inquiry);
