import React from "react";

const Signatures = ({ approvalMembers, currentUser,user }) => {
  return (
    <div id="approvals_div">
      <div id="signature_div" className="general_div containing_div">
        <div className="general_div vertical_div arabic_div">
          <span className="span_general span_arabic " id="span_special">
            :التوقيع
          </span>
        </div>
      </div>
      <div className="general_div containing_div">
        <div className="general_div vertical_div">
          {currentUser?.username === "hospital.headoforg" ? (
            <span className="span_general span_arabic">
              رئيس الإدارة المركزية لشئون المستفيدين
            </span>
          ) : (
            <span className="span_general span_arabic">
              مدير إدارة شئون المستفيدين
            </span>
          )}
          <div>
          <span className="span_general span_arabic">
            {currentUser.username}
          </span>
          <p className="span_general span_arabic">التوقيع</p>
          <img
            alt="Signature"
            style={{ width: 200, height: 50, border: "none" }}
            src={`${process.env.REACT_APP_SERVER_URL}user/downloadUserSignature?user_id=${currentUser?.id}`}
          />
          </div>
        </div>
        <div className="general_div vertical_div">
          <span className="span_general span_arabic">ادارة شئون المرضي: </span>
          {[approvalMembers[1]?.[0]].map((member, index) => (
            <div key={index} >
              <span className="span_general span_arabic">
                {member?.username}
              </span>
              <p className="span_general span_arabic"> التوقيع </p>
              <img
                alt="Signature"
                style={{ width: 200, height: 50, border: "none" }}
                src={`${process.env.REACT_APP_SERVER_URL}user/downloadUserSignature?user_id=${member?.id}`}
              />
            </div>
          ))}
        </div>
        <div className="general_div vertical_div">
          <span className="span_general span_arabic">
            اعضاء الموافقات المسبقة
          </span>
          {[approvalMembers[0]?.[0]].map((member, index) => (
            <div key={index} >
              <span  className="span_general span_arabic">
                {member?.username}
              </span>
              <p className="span_general span_arabic">التوقيع</p>
              <img
                alt="Signature"
                style={{ width: 200, height: 50, border: "none" }}
                src={`${process.env.REACT_APP_SERVER_URL}user/downloadUserSignature?user_id=${member?.id}`}
              />
            </div>
          ))}
        </div>
      </div>
              <img
              alt="Stamp"
              style={{ width: 200, height: 200, border: "none" ,position:"absolute", left:"100px" , bottom: "100px" , opacity:0.8}}
              src={`${process.env.REACT_APP_SERVER_URL}organization/downloadOrgStamp?org_id=${user?.org?.id}`}
              />
    </div>
  );
};

export default Signatures;
