import React, { useState, useEffect } from "react";
import { Popover, Badge, Button, Divider, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import logo2 from "../../assets/images/iAgree.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DraftsIcon from "@material-ui/icons/Drafts";
import "./index.scss";
import { makeStyles } from "@material-ui/core/styles";
import client from "api/apiAuth/guestClient";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import { getNotifications, receive } from "reducers/socket";
import { createBrowserHistory } from "history";
import _ from "lodash";
const {
  logo,
} = require(`weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/header`);

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: "#0066cc",
    width: "14px",
    height: "14px",
    borderRadius: "50%",
  },
  gold: {
    backgroundColor: "#0066cc",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
  },
  root: {
    "& MuiPaper-root MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded": {
      width: "500px",
    },
  },
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D5DBE0",
      borderRadius: 5,
    },
  },
}));

const HeaderRightBox = (props) => {
  const { messages, user, getNotifications, receive } = props;
  let history = createBrowserHistory({ forceRefresh: true });
  const [anchorEl1, setAnchorEl1] = useState(null);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  useEffect(() => {
    if (messages && user) {
      getNotifications({
        include: [
          {
            association: "users",
            where: {
              id: user.id,
            },
          },
        ],
        model: "Notification",
      });
    }
    receive("sendMessage");
  }, []);

  const open1 = Boolean(anchorEl1);

  const redirectInstance = async (message) => {
    await client.patch(
      `generic/update?model=NotificationUsers&id=${message.id}`,
      {
        attributes: { read: 1 },
        where: {
          notification_id: message.id,
        },
      }
    );
    history.push(`/details/${message.submissionId}`);
  };
  const showAllNotification = () => {
    history.push("/notifications");
  };

  const newMessage = messages.filter(
    (message) => message?.users?.[0]?.notification.read === false
  );
  const classes = useStyles();
  return (
    <>
      <div className="d-flex align-items-center justify-content-end popover-header-wrapper">
        <span className="pr-2">
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            style={{ color: "#FFF" }}
            badgeContent={
              messages.filter(
                (message) => message?.users?.[0]?.notification.read === false
              ).length
            }
            classes={{
              badge: newMessage.length > 0 ? classes.red : "",
            }}
          >
            <Button
              onClick={handleClick1}
              className="font-size-lg p-0 d-inline-block shadow-none border-1 text-center d-44 rounded position-relative"
              color="primary"
            >
              <span>
                <NotificationsActiveTwoToneIcon color="primary" />
              </span>
            </Button>
          </Badge>
          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              position: "absolute",
              maxWidth: "700px",
            }}
            disableScrollLock={false}
          >
            <div className="popover-custom-lg">
              <Divider />
              <Paper
                className={classes.list}
                style={{
                  minHeight: 200,
                  maxHeight: 400,
                  width: "380px",
                  overflow: "auto",
                }}
              >
                {messages &&
                  messages
                    .filter((message) =>
                      message.users?.find(
                        (notification) =>
                          notification.notification.read === false
                      )
                    )
                    .map((message, index) => (
                      <List
                        className={classes.list}
                        component="nav"
                        aria-label="main mailbox folders"
                        key={index}
                      >
                        <ListItem
                          className="notificationItem"
                          button
                          onClick={(e) => redirectInstance(message)}
                        >
                          <ListItemIcon
                            className={
                              message?.users?.[0]?.notification.read
                                ? "read"
                                : "iconNotRead"
                            }
                          >
                            <DraftsIcon />
                          </ListItemIcon>
                          <ListItemText primary={message.message} />
                          <Badge
                            variant="dot"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            badgeContent=" "
                            classes={{
                              badge:
                                message?.users?.[0]?.notification.read === false
                                  ? classes.gold
                                  : "",
                            }}
                          ></Badge>
                        </ListItem>
                        <ListItem style={{ width: "85%", marginLeft: "10%" }}>
                          <ListItemText
                            style={{ marginLeft: "10px" }}
                            primary={
                              message.created_at &&
                              new Date(message.created_at).toLocaleString()
                            }
                          />
                        </ListItem>
                        <Divider style={{ width: "95%", marginLeft: "10px" }} />
                      </List>
                    ))}
              </Paper>
              <div className="text-center py-3">
                <Button
                  className={`btn btn-primary btn-transition-none d-inline `}
                  variant="contained"
                  onClick={showAllNotification}
                >
                  <span className="btn-wrapper--label">view more</span>
                </Button>
              </div>
            </div>
          </Popover>
        </span>
        <span className="pr-2 ml-2" style={{ width: "100%"}}>
          <div
            className="avatar-icon rounded"
            style={{ width: "100%", paddingLeft: "10px" }}
          >
            <img src={logo2} alt="..." />
          </div>
        </span>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    messages: state.socket.messages,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (params) => dispatch(getNotifications(params)),
    receive: (eventName, message) => dispatch(receive(eventName, message)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderRightBox);
