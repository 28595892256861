import {
  Button,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CameraModal from "components/CameraModal";
import ErrorMessage from "components/ErrorMessage";
import ImageViewer from "components/ImageViewer";
import PdfViewer from "components/PdfViewer";
import React, { useRef, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Swal from "sweetalert2";
import { deleteFile } from "reducers/form/formActions";
import { connect } from "react-redux";
import { getValue } from "utils/GetObjectValues";
import client from "api/apiAuth/guestClient";
import {
  DecreaseRequestsNo,
  SetRequestsTokens,
} from "reducers/general/generalActions";
const axios = require("axios");

function UploadFile({
  input,
  formik,
  onValueChange,
  inputValue,
  deleteFile,
  inputError,
  inputTouched,
  submissionValue,
  enableSubmit,
  SetRequestsTokens,
  DecreaseRequestsNo,
  uniqueNo,
  progress,
  setProgress,
  index
}) {
  const [openCameraModal, setOpenCameraModal] = useState(false);
  const fileInput = useRef(null);
  const [openDocument, setOpenDocument] = useState(false);
  function uploadFile(input, file, formik, documentName, isMultiple) {

    let formData = new FormData();
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    const url = `attachment/uploadFile?documentName=${
      input.equivalent_key
    }&submission_id=${
      submissionValue?.file_code ? `${submissionValue?.file_code}` : uniqueNo
    }&comment=${inputValue?.comment}`;
    formData.append("avatar", file);
    formData.append("original_file_name", file.name);
    // formData.append("avatar", file);
    
    SetRequestsTokens({
      url,
      key: input.key,
      token: source,
    });
    client
      .post(url, formData, {
        cancelToken: source.token,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress:  (progressEvent) => {
          const progresses = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          const progres = [...progress];
          const filteredProgres = progres?.find(({id}) => id === index);
            // filteredProgres? setProgress(progres.filter((({id})=> id !== index))) : setProgress([...progres,{id:index , value:progresses}]);
           if (inputValue?.avatarFd) {
            setProgress([...progres,{id:index, value:0}])
            }else{
            if (filteredProgres){
               setProgress(progres?.filter((({id})=> id !== index)));
                setProgress([...progres,{id:index , value:progresses}])
            }else{
               setProgress([...progres,{id:index , value:progresses}])
             }
            }
        },
        onDownloadProgress: (progressEvent) => {
          const progresses = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
            );
            const progres = [...progress]
            
            const filteredProgres = progres?.find(({id}) => id === index)
            // filteredProgres ? setProgress(progres.filter((({id})=> id !== index))) : setProgress([...progres,{id:index , value:progresses}]);
            if (filteredProgres){
              setProgress(progres?.filter((({id})=> id !== index)));
              setTimeout(() => {
                setProgress([...progres,{id:index , value:progresses}])
              }, 0); 
            }else{
               setProgress([...progres,{id:index , value:progresses}])
             }
            
            // setProgress(progress);
          },
        })
        .then((result) => {
          let documentData = {
            ...result.data.results,
            input_id: input.id,
            original_file_name: file.name,
            fileObject: file,
          };
        let inputValue = submissionValue?.values?.is_email ? (getValue(formik.values, input.equivalent_key, input.itype))[0] : getValue(formik.values, input.key, input.itype)
        if (inputValue?.id) {
          documentData.id = inputValue.id;
        }
        formik.setFieldValue(
          isMultiple ? documentName : submissionValue?.values?.is_email ? input?.equivalent_key : input?.key,
          submissionValue?.values?.is_email ? [documentData]:documentData
          );
          setTimeout(() => {
            formik.setFieldTouched(documentName, true, true).then((err) => {});
          }, 0);
        })
        .catch((error) => {
          console.error(error);
          DecreaseRequestsNo();
          setProgress(0);
          formik.setFieldValue(input.key, undefined);
        });
      }
      const handleDocumentChange = (event) => {
        event.persist();
        let documentName = event.target.name,
        file = event.target.files[0];
        uploadFile(input, file, formik, documentName);
        event.target.value = "";
      };
      const showDocument = () => {
        setOpenDocument(true);
      };
      const deleteDocument = (inputKey) => {
        Swal.fire({
          title: "Are you sure you want to delete this file? ",
          showConfirmButton: true,
          confirmButtonClass: "submit",
          titleClass: "fontSize",
          icon: "question",
          confirmButtonText: "Yes",
          closeOnConfirm: false,
        }).then(function (res) {
          if (res.isConfirmed) {
            deleteFile(inputValue.avatarFd);
            formik.setFieldValue(inputKey, undefined);
            const progres = [...progress]
            setProgress(progres.filter(({id})=> id !== index))
          }
        });
      };
      return (
        <>
      <Grid container>
        {input.name && (
          <Grid item md={8} style={{ maxWidth: "56%" }}>
            <div
              className={
                "heading-4 d-block mt-4 pr-3 comment input-label-font text-left "
              }
              style={input.input_layout?.labelStyle}
            >
              {input.name}
              {(input.required && <span className="color-red">*</span>) || null}
            </div>
          </Grid>
        )}
        <Grid
          item
          md={10}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="file"
            name={input.key}
            accept={input.input_layout.acceptableExtensions}
            data-document={input.itype !== "string" && input.id}
            data-section={input.section}
            ref={fileInput}
            onChange={(ev) => {
              if (onValueChange) {
                onValueChange(input, null, ev);
              }
                handleDocumentChange(ev);
              }}
              width="60%"
              required={input.required}
              style={{ display: "none" }}
            />
            <Tooltip
              title={inputValue?.name || ""}
              arrow
              classes={{ tooltip: "p-3" }}
            >
              <div display="inline" className="documentTypography">
                {inputValue?.original_file_name || "file name"}
              </div>
            </Tooltip>
          <Button
            className={`btn btn-primary btn-transition-none d-inline upload-button`}
            disableFocusRipple
            disableRipple
            onClick={() => fileInput.current.click()}
            disabled={input.disabled}
          >
            <span>{inputValue?.avatarFd ? "Re-upload" : "Upload"}</span>
          </Button>
        </Grid>
        {getValue(formik.values, input.key, input.input_layout?.type) &&
          !inputError && (
            <>
              {(!inputValue?.id) && (
                <Grid item md={1}>
                  <Button
                    onClick={() => deleteDocument(input.key)}
                    className="btn-outline-danger border-1 btn-small preview-btn-size"
                    variant="outlined"
                  >
                    <DeleteIcon />
                  </Button>
                </Grid>
              )}
              <Grid item md={1}>
                <Button
                  onClick={showDocument}
                  className="btn-outline-primary border-1 btn-small preview-btn-size"
                  variant="outlined"
                >
                  Preview
                </Button>
              </Grid>
            </>
          )}

        <Grid item md={8} className="mt-2">
          <LinearProgress
            variant="determinate"
            className="progress-sm progress-bar-rounded progress-animated-alt progress-bar-first"
            value={inputValue?.avatarFd ? 100 : progress?.find(({id}) => id === index)? progress?.find(({id}) => id === index)?.value : 0 }
          />
        </Grid>

        <Grid item md={2} className="ml-1">
          <Typography
            variant="body2"
            color="secondary"
          >{`${inputValue?.avatarFd ? 100 :progress?.find(({id}) => id === index)? progress?.find(({id}) => id === index)?.value : 0}%`}</Typography>
        </Grid>
      </Grid>

      <ErrorMessage
        isTouched={inputTouched}
        enableSubmit={enableSubmit}
        errorMessage={inputError}
      />
      {/* preview */}
      {inputValue?.extension?.includes("pdf") ? (
        <PdfViewer
          modal={openDocument}
          setModal={setOpenDocument}
          file={inputValue?.fileObject || inputValue?.URL}
        ></PdfViewer>
      ) : (
        <ImageViewer
          open={openDocument}
          setOpen={setOpenDocument}
          file={inputValue?.fileObject || inputValue?.URL}
          local
        ></ImageViewer>
      )}
      {/* camera modal */}
      <CameraModal
        open={openCameraModal}
        setOpen={setOpenCameraModal}
        formik={formik}
        name={input.key}
        documentType={"single"}
        document={input.itype !== "string" && input.id}
        Section={input.section}
      ></CameraModal>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    enableSubmit: state.form.enableSubmit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFile: (avatarFd) => dispatch(deleteFile(avatarFd)),
    SetRequestsTokens: (params) => dispatch(SetRequestsTokens(params)),
    DecreaseRequestsNo: () => dispatch(DecreaseRequestsNo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
