import React, { useEffect, useState } from "react";

import {
  Table,
  Card,
  CardContent,

} from "@material-ui/core";

export default function LivePreviewExample({ cardsDetails }) {
  let [tableData, setTableData] = useState([]);
  let [allCards, setAllCards] = useState([]);
  let [columns, setColumns] = useState([]);
  const evalCondition = (condition, item, fieldValue) => {
    if (item[condition.field] == condition.value) return item[fieldValue];
    else {
      return "-";
    }
  };
  useEffect(() => {
    let data = [];
    allCards.forEach((element) => {
      setColumns(element.columns);
      element.count.forEach((item) => {
        let newItem = {};
        element.columns.forEach((column) => {
          if (Array.isArray(column.field)) {
            if (column.condition) {
              column.condition.forEach((condition) => {
                newItem[column.title] = evalCondition(
                  condition,
                  item,
                  condition.actualValue
                );
              });
            }
          } else {
            if (column.condition) {
              newItem[column.title] = evalCondition(
                column.condition,
                item,
                column.field
              );
            } else {
              newItem[column.title] = item[column.field];
            }
          }
        });
        data.push(newItem);
      });
    });
    setTableData(data);
  }, [allCards]);

  useEffect(() => {
    if (cardsDetails) setAllCards(cardsDetails);
  }, [cardsDetails]);
  return (
    <>
      <Card className="card-box  mb-spacing-6-x2">
        <CardContent className="pt-3 px-4 pb-4">
          <Table className="table table-hover table-striped text-nowrap mb-0">
            <thead className="thead-light">
              <tr>
                {columns.map((column) => (
                  <th className="text-center">{column.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => {
                return (
                  <tr>
                    {columns.map((column) => (
                      <td className="text-center text-danger">
                        <span>{row[column.title] || 0}</span>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}
