import { setInputKey } from "components/FormSteps/utils";
import _ from "lodash";
import {
  setEventsInputs,
  getChecklistInputs,
  setDependencyInputOptions,
} from "reducers/form/formActions";
import { setAvailableSubmissionsCount } from "reducers/settings/settingsActions";
import store from "../../../reducers/store";

import client from "api/apiAuth/guestClient";
import { lastInputPart } from "utils/ValidationSchema/helperFunctions";
const { getValue } = require("../../GetObjectValues");

const compensationByValue = (obj, inputValue) => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) =>
      typeof value === "string" && value === "$value" ? inputValue : value
    )
  );
};
const events = {
  fetchFromApi: async (attributes) => {
    let { model, limit, skip, filter, include } = attributes["fetchFromApi"];
    filter = filter && compensationByValue(filter, attributes.value);
    let { data } = await client.get("generic/find", {
      params: { model, limit, skip, filter, include },
    });
    return data;
  },
  fetchFromPoca: async (attributes, input, event, previousResult, formik) => {
    const result = await client.post("algorithm/applaySpacificAlgorithm", {
      drugName: getValue(formik.values, input.key),
      algorithmName: "getPocaScore",
    });
    return {
      [input.key]: {
        similarity: result.data.finalResult.length
          ? `${result.data.finalResult[0].mergedScore}%`
          : "0%",
        acceptance: result.data.finalResult.length
          ? "Initital Rejection"
          : "Initial Acceptance",
        similarityList: result.data.finalResult,
      },
    };
  },

  setFieldValue: (attributes, input, event, previousResult, formik) => {
    attributes["setFieldValue"].fields.forEach((field) => {
      attributes.formik.setFieldValue(
        input.key.replace(
          input.key.split(".")[input.key.split(".").length - 1],
          field
        ),
        previousResult[input.key][field]
      );
    });
  },
  fetchSelectionOptions: async (
    attributes,
    input,
    event,
    previousResult,
    formik
  ) => {
    let { rows, count } = previousResult || { rows: [], count: 0 };
    const clonedInput = _.cloneDeep(input);
    clonedInput.options = [...clonedInput.options, ...rows];

    let skip = clonedInput?.optionsParams?.skip
      ? attributes["fetchSelectionOptions"].limit +
        clonedInput?.optionsParams?.skip
      : attributes["fetchSelectionOptions"].limit;
    clonedInput.optionsParams = { count, skip };
    clonedInput.events = clonedInput.events.map((inputEvent) => {
      if (inputEvent.id == event.id) {
        if (inputEvent.attributes["fetchFromApi"])
          inputEvent.attributes["fetchFromApi"].skip = skip;
        else inputEvent.attributes["fetchFromApi"] = { skip };
      }
      return inputEvent;
    });
    store.dispatch(setEventsInputs(clonedInput));

    return clonedInput;
  },
  checkLimitation: async (attributes, input, event, previousResult) => {
    try {
      let { data } = await client.get("SubmissionsLimit/checkDayLimit");
      let AvailableSubmissionsCount = [];
      let storeSubmissionsCount =
        store.getState().settings.settings.availableSubmissionsCount;
      input.options = input.options.map(
        (obj) => data.find((o) => o.label === obj.label) || obj
      );
      let count;
      data.forEach((limit) => {
        let storedSubmissionLimit = storeSubmissionsCount.find(
          (submissionCount) => limit.label in submissionCount
        );
        if (storedSubmissionLimit?.[limit.label] == 0) {
          input.options = input.options.map((option) => {
            if (option.label == limit.label) {
              option = {
                ...option,
                disabled: true,
                message: "You have exceeded the submission limit",
              };
            }
            return option;
          });
        }

        if (limit.limitCount === "unlimited") {
          AvailableSubmissionsCount.push({
            [limit.label]: "unlimited",
            originalLimitCount: "unlimited",
          });
        } else if (
          storedSubmissionLimit?.["originalLimitCount"] > limit.limitCount
        ) {
          count =
            storedSubmissionLimit["originalLimitCount"] - limit.limitCount;
          AvailableSubmissionsCount.push({
            [limit.label]: limit.limitCount - count,
            originalLimitCount: limit.limitCount - count,
          });
        } else if (
          storedSubmissionLimit?.["originalLimitCount"] == limit.limitCount &&
          storedSubmissionLimit?.[limit.label] <
            storedSubmissionLimit?.["originalLimitCount"]
        ) {
          AvailableSubmissionsCount.push({
            [limit.label]: storedSubmissionLimit?.[limit.label],
            originalLimitCount: limit.limitCount,
          });
        } else {
          AvailableSubmissionsCount.push({
            [limit.label]: limit.limitCount,
            originalLimitCount: limit.limitCount,
          });
        }
      });
      store.dispatch(setAvailableSubmissionsCount(AvailableSubmissionsCount));

      return input;
    } catch (error) {
      console.error("error: ", error);
    }
  },
  checkRealTimeLimitation: async (attributes, input, event, previousResult) => {
    try {
      let productType = attributes.value;
      let availableSubmissionsCount =
        store.getState().settings.settings.availableSubmissionsCount;
      availableSubmissionsCount = _.cloneDeep(availableSubmissionsCount).map(
        (limitCount) => {
          if (productType in limitCount) {
            if (![0, "unlimited"].includes(limitCount[productType])) {
              limitCount[productType] -= 1;
            }
          }
          return limitCount;
        }
      );

      store.dispatch(setAvailableSubmissionsCount(availableSubmissionsCount));
      // return input;
    } catch (error) {
      console.error("error: ", error);
    }
  },

  fetchChecklistInputs: async (
    attributes,
    input,
    event,
    previousResult,
    formik,
    arrayIndexes
  ) => {
    const { section_id, formId, keys, value } = attributes;
    const inputKeys = keys.map((key) =>
      setInputKey({ key, multi_key: key }, arrayIndexes)
    );
    const inputKey = setInputKey(input, arrayIndexes);

    const values = inputKeys.reduce(
      (obj, key) =>
        key === inputKey
          ? { ...obj, [key.split(".").pop()]: value }
          : { ...obj, [key.split(".").pop()]: getValue(formik.values, key) },
      {}
    );
    if (!_.isEmpty(values) && Object.values(values).every(Boolean))
      await store.dispatch(
        getChecklistInputs({ protocol: values, formId }, section_id)
      );
  },

  filterOptions: async (
    attributes,
    input,
    event,
    previousResult,
    formik,
    arrayIndexes
  ) => {
    const { dependentInput, section_id, value, keys, formId } = attributes;

    const inputKeys = keys.map((key) =>
      setInputKey({ key, multi_key: key }, arrayIndexes)
    );
    const inputKey = setInputKey(input, arrayIndexes);

    const values = inputKeys.reduce(
      (obj, key) =>
        key === inputKey
          ? { ...obj, [lastInputPart(key)]: value }
          : { ...obj, [lastInputPart(key)]: getValue(formik.values, key) },
      {}
    );
    formik.setFieldValue &&
      formik.setFieldValue(dependentInput.formKey, undefined);
    await store.dispatch(
      setDependencyInputOptions(
        {
          input: {
            key: input.key,
            multi_key: input.multi_key,
            equivalent_key: input.equivalent_key,
          },
          dependentInput,
          formId,
          protocol: values,
        },
        section_id
      )
    );
  },
  setInstructions: async (
    attributes,
    input,
    event,
    previousResult,
    formik,
    arrayIndexes
  ) => {
    const { value, dependnetKeys, key, formId } = attributes;
    console.log("set instructions", formik, value, input);
    let dependentValues = { [input.key.split("submission.")[1]]: value };
    let otherKey = dependnetKeys.filter(
      (dependnetKey) => dependnetKey != input.key
    )[0];
    dependentValues = {
      ...dependentValues,
      [otherKey.split("submission.")[1]]: getValue(formik.values, otherKey),
    };

    console.log("dependentValues", dependentValues);
    if (!_.isEmpty(dependentValues)) {
      let { data } = await client.get("app/getInstruction", {
        params: { protocol: { ...dependentValues }, formId },
      });
      console.log(key,data.instructions);
      formik.setFieldValue(key, data.instructions);
    }
  },
};
export default events;
