import client from "api/apiAuth/guestClient";
import axios from "axios";
import { groupBy } from "lodash";
import { DATE_API } from "../constantes";

export function convertDigitIn(enDigit) {
  //  convert english numbers to arabic
  var newValue = "";
  for (var i = 0; i < enDigit?.length; i++) {
    var ch = enDigit.charCodeAt(i);
    if (ch >= 48 && ch <= 57) {
      // european digit range
      var newChar = ch + 1584;
      newValue = newValue + String.fromCharCode(newChar);
    } else if (ch === 45) {
      var newChar = ch + 2;
      newValue = newValue + String.fromCharCode(newChar);
    } else {
      newValue = newValue + String.fromCharCode(ch);
    }
  }
  return newValue;
}

export function getAge(birthDate) {
  var now = new Date();
  function isLeap(year) {
    return year % 4 == 0 && (year % 100 != 0 || year % 400 == 0);
  }

  // days since the birthdate
  var days = Math.floor(
    (now.getTime() - birthDate.getTime()) / 1000 / 60 / 60 / 24
  );
  var fixedDays = days
  var age = 0;
  if(days < 365) age = `${Math.floor(days/30)} اشهر`
  if(days < 30) age = `${days} يوم`
  // iterate the years
  for (var y = birthDate.getFullYear(); y <= now.getFullYear(); y++) {
    var daysInYear = isLeap(y) ? 366 : 365;
    if (days >= daysInYear) {
      days -= daysInYear;
      age++;
      // increment the age only if there are available enough days for the year.
    }
  }
  if(fixedDays > 365) age = `${age} سنة`
  return age;
}
export async function getTodayDate() {
  const wrapData = await axios.get(DATE_API, {
    headers: {
      Accept: "application/json",
    },
  })
  if (wrapData.status !== 200){
    getTodayDate()
  }else{
   const {data} = wrapData
   return data?.datetime;
  }  
}

export function getUserSignatures(tasks, currentUser) {
  let approvalSteps, approvalMembers;
  if (currentUser.roles[0].name === "Head of C A B D (Signer)") {
    approvalSteps = [9, 28, 11, 12, 15, 16, 30, 31, 34, 35];
    approvalMembers = ["C A B D Reviewers", "Administrator Of C A B D"];
  } else {
    approvalSteps = [2, 21, 3, 4, 22, 23];
    approvalMembers = ["Pre approval reviewer", "Patient Affairs manager"];
  }
  console.log(tasks);
  tasks = tasks.filter((task) => approvalSteps.includes(task.step.id));
  const groupedTasks = groupBy(tasks, "step.assignees.0.title");
  console.log(groupedTasks);
  return approvalMembers.map((member) => {
    console.log(groupedTasks[member]);
    return groupedTasks[member]?.map((task) => task.users[0]);
  });
}
