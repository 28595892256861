import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import styles from './index.module.css';
import client from '../../api/apiAuth/guestClient';
import SpinnerLoader from '../../components/SpinnerLoader';
import dashboardComponents from '../../components/Dashboard';
import { connect } from 'react-redux';


const Dashboard = ({ forms }) => {
  const [components, setComponents] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await client.get('/dashboard/getDashboardInformation');
        setComponents(response.data.statistics);


      } catch (err) {
        console.log(err);
      }
    };

    fetchStatistics();
  }, []);



  return (
    <>
      {/* {loading ? (
        <SpinnerLoader />
      ) : ( */}
      <Grid container>
        <Card className={`p-3 ${styles['statcard']}`}>
          {components.map((component, index) => {
            let NewComponent = dashboardComponents[component.component];
            return (
              <NewComponent
                cardsDetails={component.componentDetails}
                key={index}
              />
            );
          })}
        </Card>
      </Grid>
      {/* )} */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    forms: state.formList.forms
  };
};
export default connect(mapStateToProps, null)(Dashboard);
