import React from "react";

import { convertDigitIn, getAge } from "../utils/utils";

const Table = ({
  setValues,
  values,
  letter,
  formValues,
  passedToCommittee,
}) => {
  // pass all values from users to values state check certificates > index.js  & TransitionForm.js to understand more
  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.value === "on" ? event.target.checked : event.target.value,
    });
  };

  return (
    <div>
      <table>
        <tbody>
          {letter.familyHead && (
            <tr>
              <th
                scope="row"
                style={{
                  width: "250px",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                اسم عائل الاسرة
              </th>
              <td style={{ width: "250px", textAlign: "center" }}>
                {letter.familyHead.name}
              </td>
              <th style={{ width: "250px", textAlign: "center" }}>
                الرقم القومى لعائل الاسرة
              </th>
              <td style={{ width: "200px" }}>
                {convertDigitIn(letter.familyHead.national_id)}
              </td>
              <th style={{ textAlign: "center" }}> صلة القرابة</th>
              <td style={{ width: "60px" }}>{letter.familyHead.relation}</td>
            </tr>
          )}
          <tr>
            <th
              scope="row"
              style={{
                width: "150px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              اسم المريض
            </th>
            <td style={{ width: "250px", textAlign: "center" }}>
              {letter.patient.name}
            </td>
            <th scope="row" style={{ width: "150px", textAlign: "center" }}>
              رقم القومي للمريض
            </th>
            <td>{convertDigitIn(letter.patient.national_id)}</td>
            <th style={{ width: "150px", textAlign: "center" }}>السن</th>
            <td style={{ width: "150px" }}>
              {convertDigitIn(
                getAge(new Date(letter.patient.birth_date)).toString()
              )}
            </td>
          </tr>
          <tr>
            <th style={{ textAlign: "center" }}>رقم الملف العائلي</th>
            <td>
              {convertDigitIn(
                letter.patient.membership_member?.toString() || ""
              )}
            </td>
            <th style={{ textAlign: "center" }} colSpan={2}>
              رقم التواصل
            </th>
            <td colSpan={2}>
              {convertDigitIn(
                letter.familyHead?.contact_number ||
                  letter.patient?.contact_number
              )}
            </td>
          </tr>
          {letter.form.id === 2 ? (
            <>
              <tr>
                <th scope="row" style={{ width: "100px", textAlign: "center" }}>
                  التشخيص
                </th>
                <td colSpan="6">{letter.diagnosis?.name}</td>
              </tr>
              <tr>
                <th scope="row" style={{ width: "100px", textAlign: "center" }}>
                  الدواء المطلوب
                </th>
                <td colSpan="6">{letter.drug.name}</td>
              </tr>
              <tr>
                <th scope="row" style={{ width: "100px", textAlign: "center" }}>
                  التعليمات
                </th>
                <td colSpan="6">{letter.checklist.conclusion.instructions}</td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <th scope="row" style={{ width: "100px", textAlign: "center" }}>
                  الخدمة المطلوبة
                </th>
                <td colSpan="6">{letter.service.name}</td>
              </tr>
              <tr>
                <th scope="row" style={{ width: "100px", textAlign: "center" }}>
                  كود الخدمة المطلوبة
                </th>
                <td colSpan="6">{letter.service.UHIAID}</td>
              </tr>
              <tr>
                <th scope="row" style={{ width: "100px", textAlign: "center" }}>
                  التشخيص
                </th>
                <td colSpan="6">{letter.service.diagnosis}</td>
              </tr>
            </>
          )}

          <tr>
            <th scope="row" style={{ width: "100px", textAlign: "center" }}>
              اسم المنشأة الطبية
            </th>
            <td>
              {letter.values.medical_facility ? (
                letter.values.medical_facility
              ) : (
                <input
                  type="text"
                  style={{ border: "none" }}
                  name="medical_facility"
                  value={values.medical_facility}
                  placeholder="................."
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              )}
            </td>
            <th scope="row" style={{ textAlign: "center" }}>
              اسم الطبيب
            </th>
            <td colSpan={3}>
              {letter.values.doctor_name ? (
                letter.values.doctor_name
              ) : (
                <input
                  type="text"
                  style={{ border: "none" }}
                  name="doctor_name"
                  value={values.doctor_name}
                  placeholder="................."
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ width: "100px", textAlign: "center" }}>
              التخصص
            </th>
            <td>
              {letter.values.specialization ? (
                letter.values.specialization
              ) : (
                <input
                  type="text"
                  style={{ border: "none" }}
                  name="specialization"
                  value={values.specialization}
                  placeholder="................."
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              )}
            </td>
            <th scope="row" style={{ textAlign: "center" }}>
              الفئة
            </th>
            <td colSpan="3">
              {letter.values.doctor_career ? (
                letter.values.doctor_career
              ) : (
                <input
                  type="text"
                  style={{ border: "none" }}
                  name="doctor_career"
                  value={values.doctor_career}
                  placeholder="................."
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ textAlign: "center" }}>
              المدة المطلوبة
            </th>
            <td colSpan={letter.form.id !== 2 ? 2 : 0}>
              {formValues.duration || letter.values.duration}
            </td>
            <th scope="row" style={{ textAlign: "center" }}>
              الوحدة
            </th>
            <td colSpan={letter.form.id !== 2 ? 2 : 0}>
              {formValues.duration_unit || letter.values.duration_unit}
            </td>
            {letter.form.id === 2 && (
              <>
                <th scope="row" style={{ textAlign: "center" }}>
                  الجرعة المطلوبة
                </th>
                <td colSpan={0}>{formValues.dosage || letter.values.dosage}</td>
              </>
            )}
          </tr>
          {passedToCommittee && (
            <tr>
              <th scope="row" style={{ width: "100px", textAlign: "center" }}>
                جهة العرض
              </th>
              <td colSpan="6">
                .تم العرض علي لجنة الاستشاريين من قبل الإدارة المركزية لشئون
                المستفيدين
              </td>
            </tr>
          )}
          <tr style={{ height: "50px" }}>
            <th scope="row" style={{ width: "100px", textAlign: "center" }}>
              القرار
            </th>
            <td colSpan={6}>
              {/* <textarea
                required
                autoFocus
                style={{ resize: "none" }}
                name="decision"
                id=""
                cols="20"
                className="text-right"
                rows="2"
                value={values.decision}
                placeholder="................."
                onChange={(e) => {
                  handleChange(e);
                }}
              ></textarea> */}
              {values.decision}
            </td>
          </tr>
          <tr style={{ height: "50px" }}>
            <th scope="row" style={{ width: "100px", textAlign: "center" }}>
              التعليمات
            </th>
            <td colSpan={6}>
              {letter.form.id === 2 ? letter.checklist.conclusion.instructions : letter?.service?.instructions}
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ width: "100px", textAlign: "center" }}>
              ملاحظات
            </th>
            <td colSpan={6} style={{ textAlign: "center" }}>
              <ul style={{ listStyle: "none" }}>
                <li>
                  يتم الالتزام ببنود حزم الخدمات وإرفاق الاستيكر /الباركود مع
                  المطالبة -{" "}
                </li>
                <li>
                  البيانات الواردة بالقرار يجب أن تكون واضحة وليست رموز أو حروف
                  -{" "}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
