//0 ---> My Assigned Applications
//1 ----> Not assigned Applications
//2 ----> Ongoing Applications
//3 ----> Completed Applications

const menuObj = {
  shared: [],
  Hospital: [
    {
      id: 7,
      name: "Service Drafts",
    },
    {
      id: 8,
      name: "Drug Drafts",
    },
    {
      id: 12,
      name: "Emails",
    }
  ],
};
export default menuObj;
