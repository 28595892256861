export const FETCH_WORKLST = "FETCH_WORKLST";
export const FETCH_WORKLST_PENDING = "FETCH_WORKLST_PENDING";
export const FETCH_WORKLST_FULFILLED = "FETCH_WORKLST_FULFILLED";
export const FETCH_WORKLST_REJECTED = "FETCH_WORKLST_REJECTED";

export const FETCH_WORKLST_LAYOUT = "FETCH_WORKLST_LAYOUT";
export const FETCH_WORKLST_LAYOUT_PENDING = "FETCH_WORKLST_LAYOUT_PENDING";
export const FETCH_WORKLST_LAYOUT_FULFILLED = "FETCH_WORKLST_LAYOUT_FULFILLED";
export const FETCH_WORKLST_LAYOUT_REJECTED = "FETCH_WORKLST_LAYOUT_REJECTED";

export const GET_SUBMISSIONS_IDS = "GET_SUBMISSIONS_IDS";
export const GET_SUBMISSIONS_IDS_PENDING = "GET_SUBMISSIONS_IDS_PENDING";
export const GET_SUBMISSIONS_IDS_FULFILLED = "GET_SUBMISSIONS_IDS_FULFILLED";
export const GET_SUBMISSIONS_IDS_REJECTED = "GET_SUBMISSIONS_IDS_REJECTED";

export const FETCH_FORMS = "FETCH_FORMS";
export const FETCH_FORMS_PENDING = "FETCH_FORMS_PENDING";
export const FETCH_FORMS_FULFILLED = "FETCH_FORMS_FULFILLED";
export const FETCH_FORMS_REJECTED = "FETCH_FORMS_REJECTED";

export const FETCH_FILTER_DATA = "FETCH_FILTER_DATA";
export const FETCH_FILTER_DATA_PENDING = "FFETCH_FILTER_DATA_PENDING";
export const FETCH_FILTER_DATA_FULFILLED = "FETCH_FILTER_DATA_FULFILLED";
export const FETCH_FILTER_DATA_REJECTED = "FETCH_FILTER_DATA_REJECTED";

export const FETCH_FILTER_DATA_MODEL = "FETCH_FILTER_DATA_MODEL";
export const FETCH_FILTER_DATA_MODEL_PENDING =
  "FETCH_FILTER_DATA_MODEL_PENDING";
export const FETCH_FILTER_DATA_MODEL_FULFILLED =
  "FETCH_FILTER_DATA_MODEL_FULFILLED";
export const FETCH_FILTER_DATA_MODEL_REJECTED =
  "FETCH_FILTER_DATA_MODEL_REJECTED";

export const FETCH_TIMESLOTS_SCHEDULE = "FETCH_TIMESLOTS_SCHEDULE";
export const FETCH_TIMESLOTS_SCHEDULE_PENDING =
  "FETCH_TIMESLOTS_SCHEDULE_PENDING";
export const FETCH_TIMESLOTS_SCHEDULE_FULFILLED =
  "FETCH_TIMESLOTS_SCHEDULE_FULFILLED";
export const FETCH_TIMESLOTS_SCHEDULE_REJECTED =
  "FETCH_TIMESLOTS_SCHEDULE_REJECTED";

export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_USER_DATA_PENDING = "FETCH_USER_DATA_PENDING";
export const FETCH_USER_DATA_FULFILLED = "FETCH_USER_DATA_FULFILLED";
export const FETCH_USER_DATA_REJECTED = "FETCH_USER_DATA_REJECTED";

export const FETCH_ENTITY_DATA_AUX_1 = "FETCH_ENTITY_DATA_AUX_1";
export const FETCH_ENTITY_DATA_AUX_1_PENDING = "FETCH_ENTITY_DATA_AUX_1_PENDING";
export const FETCH_ENTITY_DATA_AUX_1_FULFILLED = "FETCH_ENTITY_DATA_AUX_1_FULFILLED";
export const FETCH_ENTITY_DATA_AUX_1_REJECTED = "FETCH_ENTITY_DATA_AUX_1_REJECTED";

export const FETCH_ENTITY_DATA_AUX_2 = "FETCH_ENTITY_DATA_AUX_2";
export const FETCH_ENTITY_DATA_AUX_2_PENDING = "FETCH_ENTITY_DATA_AUX_2_PENDING";
export const FETCH_ENTITY_DATA_AUX_2_FULFILLED = "FETCH_ENTITY_DATA_AUX_2_FULFILLED";
export const FETCH_ENTITY_DATA_AUX_2_REJECTED = "FETCH_ENTITY_DATA_AUX_2_REJECTED";

export const FETCH_ENTITY_DATA_AUX_3 = "FETCH_ENTITY_DATA_AUX_3";
export const FETCH_ENTITY_DATA_AUX_3_PENDING = "FETCH_ENTITY_DATA_AUX_3_PENDING";
export const FETCH_ENTITY_DATA_AUX_3_FULFILLED = "FETCH_ENTITY_DATA_AUX_3_FULFILLED";
export const FETCH_ENTITY_DATA_AUX_3_REJECTED = "FETCH_ENTITY_DATA_AUX_3_REJECTED";

export const FETCH_ENTITY_CHECKLIST = "FETCH_ENTITY_CHECKLIST";
export const FETCH_ENTITY_CHECKLIST_PENDING = "FETCH_ENTITY_CHECKLIST_PENDING";
export const FETCH_ENTITY_CHECKLIST_FULFILLED = "FETCH_ENTITY_CHECKLIST_FULFILLED";
export const FETCH_ENTITY_CHECKLIST_REJECTED = "FETCH_ENTITY_CHECKLIST_REJECTED";

export const FETCH_MODEL_DATA = "FETCH_MODEL_DATA";
export const FETCH_MODEL_DATA_PENDING = "FETCH_MODEL_DATA_PENDING";
export const FETCH_MODEL_DATA_FULFILLED = "FETCH_MODEL_DATA_FULFILLED";
export const FETCH_MODEL_DATA_REJECTED = "FETCH_MODEL_DATA_REJECTED";

export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_DATA_PENDING = "UPDATE_USER_DATA_PENDING";
export const UPDATE_USER_DATA_FULFILLED = "UPDATE_USER_DATA_FULFILLED";
export const UPDATE_USER_DATA_REJECTED = "UPDATE_USER_DATA_REJECTED";

export const DELETE_USER_DATA = "DELETE_USER_DATA";
export const DELETE_USER_DATA_PENDING = "DELETE_USER_DATA_PENDING";
export const DELETE_USER_DATA_FULFILLED = "DELETE_USER_DATA_FULFILLED";
export const DELETE_USER_DATA_REJECTED = "DELETE_USER_DATA_REJECTED";

export const CREATE_USER_DATA = "CREATE_USER_DATA";
export const CREATE_USER_DATA_PENDING = "CREATE_USER_DATA_PENDING";
export const CREATE_USER_DATA_FULFILLED = "CREATE_USER_DATA_FULFILLED";
export const CREATE_USER_DATA_REJECTED = "CREATE_USER_DATA_REJECTED";

export const UPDATE_ENTITY_DATA = "UPDATE_ENTITY_DATA";
export const UPDATE_ENTITY_DATA_PENDING = "UPDATE_ENTITY_DATA_PENDING";
export const UPDATE_ENTITY_DATA_FULFILLED = "UPDATE_ENTITY_DATA_FULFILLED";
export const UPDATE_ENTITY_DATA_REJECTED = "UPDATE_ENTITY_DATA_REJECTED";

export const DELETE_ENTITY_DATA = "DELETE_ENTITY_DATA";
export const DELETE_ENTITY_DATA_PENDING = "DELETE_ENTITY_DATA_PENDING";
export const DELETE_ENTITY_DATA_FULFILLED = "DELETE_ENTITY_DATA_FULFILLED";
export const DELETE_ENTITY_DATA_REJECTED = "DELETE_ENTITY_DATA_REJECTED";

export const CREATE_ENTITY_DATA = "CREATE_ENTITY_DATA";
export const CREATE_ENTITY_DATA_PENDING = "CREATE_ENTITY_DATA_PENDING";
export const CREATE_ENTITY_DATA_FULFILLED = "CREATE_ENTITY_DATA_FULFILLED";
export const CREATE_ENTITY_DATA_REJECTED = "CREATE_ENTITY_DATA_REJECTED";
