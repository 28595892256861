import client from "api/apiAuth/guestClient";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import { setViewSelectInputs } from "reducers/form/formActions";
import { getOnchangeEvents } from "utils/FormUtils/Events/EventsHelperFunctions";
import Info from "../Info";

function ViewSelect({
  input,
  formik,
  inputValue,
  onValueChange,
  arrayIndexes,
  inputEvents,
  viewSelectInputs,
  setViewSelectInputs,
}) {
  const findViewSelectInput = useCallback(
    () => viewSelectInputs.find((e) => e.id === input.id),
    [viewSelectInputs, input]
  );

  const findEventInput = (type) =>
    inputEvents.find((e) => {
      return (
        e.id === input.id &&
        (type ? e.events.some((ev) => ev.trigger === type) : true)
      );
    });

  const setInitialOptions = useCallback(
    async function () {
      const viewSelectInput = findViewSelectInput();
      if (
        inputValue &&
        !viewSelectInput.options.some((option) => option.value === inputValue)
      ) {
        let params = {
          model: input.input_layout.view,
          id: inputValue,
        };
        const { data } = await client.get("/generic/findById", { params });
        const clonedInput = _.cloneDeep(viewSelectInput);
        clonedInput.options.push({
          ...data,
          label: input.input_layout.searchFields
            .map((field) => data[field])
            .join(", "),
          value: inputValue,
        });
        setViewSelectInputs(clonedInput);
      }
    },
    [inputValue, findViewSelectInput, input.input_layout, setViewSelectInputs]
  );

  useEffect(() => {
    setInitialOptions();
  }, [setInitialOptions]);

  async function loadOptions(search, loadedOptions) {
    let requestBody = {
      model: input.input_layout.view,
      limit: input.input_layout.limit,
      skip: loadedOptions.length,
    };
    if (search && search.length) {
      requestBody.filters = [
        {
          operator: "or",

          value: input.input_layout.searchFields.map((f) => ({
            field: `$${f}$`,
            operator: "like",
            value: `%${search}%`,
          })),
        },
      ];
    }

    const response = await client.post("/generic/find", requestBody);
    const options = response.data.rows.map((item) => {
      return {
        ...item,
        value: item[input.input_layout.searchId],
        label: input.input_layout.searchFields
          .map((field) => item[field])
          .join(", "),
      };
    });
    const clonedInput = _.cloneDeep(findViewSelectInput());
    clonedInput.options = options;
    setViewSelectInputs(clonedInput);
    return {
      options: clonedInput.options,
      hasMore:
        loadedOptions.length + input.input_layout.limit < response.data.count,
    };
  }
  return (
    <>
      <AsyncPaginate
        placeholder={input.name}
        value={
          inputValue
            ? {
                label: findViewSelectInput()?.options.find(
                  (item) => item.value === inputValue
                )?.label,
                value: inputValue,
              }
            : ""
        }
        isDisabled={input.disabled}
        loadOptions={loadOptions}
        styles={{
          menu: (provided) => {
            return { ...provided, zIndex: 2 };
          },
        }}
        onChange={(ev) => {
          if (onValueChange) onValueChange(input, ev.value);
          formik.setFieldValue(input.key, ev.value);
          const eventInput = findEventInput("onChange");
          if (eventInput && ev.value)
            getOnchangeEvents(eventInput, formik, ev.value, arrayIndexes);
        }}
        onBlur={() => {
          formik.setFieldTouched(input.key, true);
        }}
      />
      <Info
        value={inputValue}
        options={findViewSelectInput()?.options}
        input={input}
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  inputEvents: state.form.inputEvents,
  viewSelectInputs: state.form.viewSelectInputs,
});
const mapDispatchToProps = (dispatch) => ({
  setViewSelectInputs: (input) => dispatch(setViewSelectInputs(input)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewSelect);
