export const config = (mapper) => {
  return [
    {
      tabId: 2,
      sectionId: 1,
      attachments: [
        "medical_report",
        "medical_reports",
        "full_lab",
        "ct_or_pet",
        "recent_radiology_after_treatment_with_comperision",
        "pathology",
        "echo",
        "full_lab_t3__t4__tsh",
        "medical_report_with_confirmed_pathology_of_renal_tumo__previous_therapy_given",
        "recent_radiography_(ct_or_pet)_after_1st_line_therapy",
        "full_lab_cbcb_lood_sugar__urea_creatinine_liver_function_test",
        "piolt_form",
        "afp",
        "triphesic_ct",
        "full_lab_cbcpt_inr_liver_kidney_function",
        "full_lab_cbcpt__inr_liver_kidney_function",
        "old_afp__new_afp",
        "full_lab_cbcpt__inr___liver___kidney_function",
        "hepatitis_investigation___anti__ccp_test",
        "quantiferon_tb_gold",
        "lab_test__esr___crp",
        "pilot_form",
        "mri_for_sacroiliac_joints",
        "lab_test_er_pr__positive",
        "ct____mri_chest",
        "bone_scan",
        "abdomenal_pelvic_with_contrasr",
        "complete_pathology_with_lymph_node_status",
        "her_2_by_fish",
        "echocardiography",
        "her2_by_fishor_sish",
        "pathology_of_brain_astrocytoma",
        "recent_mri_brain_after_surgery",
        "mr_spectroscopy_:_if_surgery_can_not_be_done",
        "mri_or_mrs  brain_after_temodal",
        "pathology_(_er____pr)",
        "medical_report(complete_history___physical_examination)",
        "diagnostic_bilateral_mammogram_ultrasound",
        "pathology",
        "determination_of_tumor_er/pr_status",
        "her_2",
        "ct_/mri_chest",
        "abdomen___pelvis_with_contrast",
        "pathology__surgical_pathology_report_indicatin_residual_disease",
        "ct_or_mri_chest",
        "abdomen__pelvis_with_contrast",
        "medical_report_with_patient_history_current_treatment",
        "dexa",
        "lab_test_cbcrenal_&_liver_function___s_calcium_____uric_acid___s_electrolytes_(_sodium_&_potassium_)___deletion_p17",
        "bone_marrow_examination",
        "ct_chest",
        "lab_test_liver_function_plate_count",
        "histroy_of__inadequate_response",
        "medical_report_with_all_diagnostic_investigation_done",
        "all_lab_test",
        "full_lab_cbcliver____renal_fuction",
        "bone_marrow_exam",
        "ct",
        "hcv__hbv_markers_cd20",
        "medical_report_with_confirmed_diagnosis_of_cml__previous_treatment",
        "recent_bcr_abl_after_prior_therapy",
        "full_lab__cbcrenal___liver_functions",
        "bone_marrow",
        "full_lab__cbcrenal___liver_functions__lipid_profile__hba1_c",
        "ecg___echo",
        "medical_report_with_hepatitis_investigation",
        "anti__ccp_test_quantiferon_tb_gold",
        "full_lab__cbccrp___esr",
        "fecal_calprotectin",
        "medical_report_with__hepatitis_investigation",
        "pilot_form_full_lab__esr___cbccrp___quantiferon_tb_gold___anti__ccp_test______calprotectin",
        "full_lab_esr___crp___quantification_tb___hepatitis_investigation",
        "pilot_form",
        "rh_factor",
        "full_lab_esr_crp_quantification_tb_hepatitis_investigation",
        "medical_report_with_previous_treatment",
        "performance_status",
        "test_lab__platelets___wbcs_count_____hepatic_____renal_functions",
        "ct/pet_scan",
        "all_lab_test_inhibitor_titre",
        "factor_8_(_<1%)",
        "medical_report_with_pathology",
        "egfr",
        "recent_radiography",
        "alk_rearrangement",
        "medical_report_with_confirmed_pathology_of_mesothelioma",
        "recent_ct_scan",
        "lab_test__cbc____urea___creatinin____liver_functions",
        "pathology_of_colon__cancer",
        "full_labs__cbc_____urea______creatinine____liver_function_test____pt___pc____inr",
        "radiography_confirmin_metastasis_(ct_or_pet.__ct)",
        "recent_radiography_after_treatment_with_avastin",
        "confirmed_pathologic_diagnosis_of_colonic_cancer__with__kras/nras  status",
        "radiographic_confirmin_metastases",
        "full_labs__cbc_____urea______creatinine____liver_function_test",
        "recent_radiography_after_treatment_with_erbitux_مع_المقارنة_بالسابقه",
        "lab_test__cbc____renal___kidney_function____coagulation_profile",
        "radiology",
        "pathology_of_prostate_cancer",
        "lab_test__cbc_____renal_____liver_function___serum_testosterone_____psa",
        "lab_test__renal_function",
        "pathology_report",
        "lab_test__cbc",
        "lab_test__cbc__platlet___wbc",
        "medical_report_with_recent_ca.__125",
        "confirmed_pathology_of_ovarian_cancer__prior_chemotherapy_given",
        "recent_radiography_showin_disease_progression_after_prior_therapy",
        "recent_radiography_(_ct_or_pet/ct)_after_treatment",
        "medical_repory_with_previous__treatment",
        "iga____igg____igm_______bone_marrow____immunofixation____b2_microglobulin",
        "skeletal__survey__pet_or_ct",
        "lab_test__cbc____urea______creatinine_clearance____uric_acid",
        "medical_report_with_confirmed_diagnosis_of_multiple_myeloma",
        "skeletal_survey_or_pet_or_ct",
        "lab_test__serum_calcium____urea__s__calcium__creatinine____creatinine_clearance",
        "bone_scan",
        "lab_test__iga____igg____igm",
        "b2_microglobulin_____immunofixation",
        "medical_report_with_confirmed_diagnosis_of__lymphoma",
        "lab_test_cd_20__pt___pc___inr___hepatitis_markers_b__c",
        "recent_radiography_after_treatment_with_mabthera_hepatitis__b__surface_antigen_core_antibody",
        "lab_test_of_serum_calcium",
        "dexa_scan",
        "medical_report_with_patient_history",
        "lab_test_serum_calcium",
        "anti_ccp_test_quantiferon_tb_gold",
        "full_lab_cbccrp_esr",
        "full_lab__cbccrp_esr",
        "lab_test_anti__ccp___cbc____esr___crp___quantiferon_tb_gold",
        "lab_test_recent_complete_cbc",
        "medical_report_with_history_form_(symptoms)___mpn__10_score___ipss_score",
        "recent_lab_test__cbc",
        "ultrasound_report_(spleen_measure)",
        "previous_treatment_in_medical_report__bone_marrow_(_grade_of_reticulan)",
        "chromogranin_(ipt)",
        "lab_test",
        "aspergillosis_test__x_ray",
        "positive_galactomanin_or_sputum_culture",
        "lab_test_check_alt__serum_creatinine____check_hbv_dna____liver_profile____cbc",
        "abdominal_ultrasound",
        "α_fetoprotein",
        "lab_test_of_hcv_rna_+ve_by_quantitative_pcr____hb_level___cbc",
        "lab_test_of_na___k_test___renal_function_test",
        "medical_report_with_recentbcr_abl_after_prior_therapy__bone_marrow",
        "lab_test_of__cbc___renal____kidney_function",
        "triphsic_ct",
        "lab_test_of_liver_function",
        "lab_test_of_hba1c_level_test__cbc",
        "lab_test_of_t3___t4___tsh",
        "medical_report_with_pathology_of_colon_cancer",
        "lab_test_of____creatnin____liver_function___pt___pc___inr",
        "radiology_confirmin_metastasis(ct___pet)",
        "medical_report_with",
        "ffa____oct",
        "lab_test_of_cbc__renal__kideny_function__s_calcium____uric_acid____electrolyte(na_k)____deletionp17___bone_marrow_exam",
        "ct_chest_abdomen___pelvic_with_contrast",
        "medical_report_with_bone_marrow_exam",
        "lab_test_of_cbc___renal___kidney_function",
        "pathology____er___pr____her2_status",
        "recent_ct_scan_or_bone_scan____comparative_radiograph",
        "recent_radiology",
        "lab_test_of_hba1c_level_test",
        "medical_report_full_detalis_of_the_case",
        "pregnancy_test",
        "doppler",
        "radiololgy_report",
        "lab_test_of__cd20____pt____pc____inr___hepatites_c_test",
        "lab_test_of_urine_analysis",
        "mri",
        "lab_test_of__serum_testosterone____psa____cbc____renal____liver_function",
        "lab_test_of__kidney_function",
        "medical_report_wit_pilot_form",
        "medical_report_with_history_of_in_adequate_response",
        "lab_test_of_gfr___s_creatnin",
        "lab_test_of_serum_creatnine___serum_albumin___albumin_creatinine_ratio___kidney_biobsy____urin_analysis",
        "lab_test_of_serum_creatnine__serum_albumin___albumin_creatinine_ratio",
        "kidney_biobsy___urin_analysis",
        "s_urea",
        "ultrsound",
        "lab_test_of_crp___esr",
        "mri_for_sacroiliac_of_joints",
        "medical_report__with_hepatitis_investigation_of_pilot_form",
        "lab_test_of_esr____cbc______anti__ccp_test___quantiferon_tb_gold",
        "lab_test_of__cbc",
        "medical_report_with_pilot_form",
        "gh_test",
        "gh_test_or_lab_test",
        "medical_report",
        "medical_reports",
        "eco",
        "heart_drawing",
        "cardiac_enzymes",
        "coronary_angiography",
        "CT",
        "coronary",
        "rays",
        "pathology_sample",
        "electromyography_of_the_optic_nerve",
        "transsectional_rays",
        "magnetic_resonance_rays",
        "compatibility_checks",
        "arm_checks",
        "holistic_analysis",
        "complete_blood_picture_analysis",
        "optometry",
        "eye_pressure_measurement",
        "referral_request_or_entry_permit",
      ].map((e) => ({
        type: "document",
        equivalent_key: e,
        underRevision: true,
        passWithRejection: false,
        mandatoryFileRejectionInBackword: true,
        mandatoryFileRejectionReviewers: [
          "Pre approval reviewer",
          "Patient Affairs manager",
          "Beneficiary Affairs manger",
        ],
        style: { gridSize: 4, display: true },
        uploadedBy: "Hospital",
        reviewers: [
          "Pre approval reviewer",
          "Patient Affairs manager",
          "Beneficiary Affairs manger",
          "C A B D Reviewer",
          "Committe Member",
          "Administrator of C A B D",
          "Head of C A B D (Signer)",
        ],
      })),
    },
    {
      tabId: 3,
      sectionId: 1,
      attachments: [
        {
          type: "document",
          equivalent_key: "extra_attachments",
          underRevision: true,
          passWithRejection: false,
          style: { gridSize: 4, display: true },
          uploadedBy: "Hospital",
          rejectionInBackwordTransitions: true,
          mandatoryFileRejectionReviewers: [
            "Pre approval reviewer",
            "Patient Affairs manager",
            "Beneficiary Affairs manger",
          ],
          reviewers: [
            "Pre approval reviewer",
            "Patient Affairs manager",
            "Beneficiary Affairs manger",
            "C A B D Reviewer",
            "Committe Member",
            "Administrator of C A B D",
            "Head of C A B D (Signer)",
          ],
        },
      ],
    },

    {
      tabId: 4,
      sectionId: 1,
      attachments: [
        {
          type: "document",
          equivalent_key: "acceptance_letter",
          underRevision: true,
          passWithRejection: false,
          style: { gridSize: 4, display: true },
        },
      ],
    },
  ];
};

export { config as documentsConfig };
