export const config = (mapper) => [
  {
    tabId: 1,
    sections: [
      {
        id: 1,
        title: "Patient",
        attributes: [
          {
            label: "National Id",
            type: "string",
            key: "submission.patient.national_id",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Patient Name",
            type: "string",
            key: "submission.patient.name",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Contact Number",
            type: "string",
            key: "submission.patient.contact_number",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Birth Date",
            type: "date",
            key: "submission.patient.birth_date",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Weight",
            type: "number",
            key: "submission.patient.patientProfile.weight",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Height",
            type: "number",
            key: "submission.patient.patientProfile.height",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Membership Member",
            type: "number",
            key: "submission.patient.membership_member",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Gender",
            type: "string",
            key: "submission.patient.patientProfile.gender",
            style: { gridSize: 3, display: true },
          },
          {
            label: "City",
            type: "string",
            key: "submission.patient.city",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Address",
            type: "string",
            key: "submission.patient.address",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Supervising Hospital",
            type: "string",
            key: "submission.supervisingHospital.name",
            style: { gridSize: 3, display: true },
          },
          {
            label: "Medical Summary",
            type: "html",
            key: "submission.patient.patientProfile.medical_summary",
            style: { gridSize: 3, display: true },
          },
        ],
      },
      {
        id: 2,
        title: "Approval Information",
        // group: "drugs",

        attributes: [
          {
            label: "Approval Type",
            key: "submission.values.claim_type",
            type: "string",
            style: { gridSize: 4, display: true },
            belongsToGroup: true,
          },
          {
            label: "Approval ID",
            key: "submission.values.claim_id",
            type: "string",
            style: { gridSize: 4, display: true },
            belongsToGroup: true,
          },
          {
            label: "Diagnosis",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.diagnosis.name",
          },
          {
            label: "Drug",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.drug.name",
          },
          {
            label: "Instructions",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.checklist.conclusion.instructions",
          },
          {
            label: "Service",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.service.name",
          },

          {
            label: "Service UHIA ID",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.service.UHIAID",
          },
          {
            label: "Diagnosis",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.service.diagnosis",
          },
          {
            label: "Duration",
            key: "submission.values.duration",
            style: { gridSize: 4, display: true },
            type: "number",
            belongsToGroup: true,
          },
          {
            label: "Duration Unit",
            key: "submission.values.duration_unit",
            style: { gridSize: 4, display: true },
            type: "string",
            belongsToGroup: true,
          },
          {
            label: "الجرعة المقررة ",
            key: "submission.values.dosage",
            style: { gridSize: 4, display: true },
            type: "string",
            belongsToGroup: true,
          },
          {
            label: "Treatment Description",
            key: "submission.values.treatment_description",
            style: { gridSize: 4, display: true },
            type: "html",
            belongsToGroup: true,
          },
          {
            label: "Instructions",
            key: "submission.service.instructions",
            style: { gridSize: 4, display: true },
            type: "string",
            belongsToGroup: true,
          },
        ],
      },
      {
        id: 3,
        title: "Doctor Information",

        attributes: [
          {
            label: "Doctor Name",
            key: "submission.values.doctor_name",
            type: "string",
            style: { gridSize: 4, display: true },
            belongsToGroup: true,
          },
          {
            label: "Specialization",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.values.specialization",
          },
          {
            label: "Doctor Career",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.values.doctor_career",
          },
          {
            label: "Medical Facility",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.values.medical_facility",
          },
        ],
      },
      {
        id: 4,
        title: "Family Head Information",

        attributes: [
          {
            label: "National Id",
            key: "submission.familyHead.national_id",
            type: "string",
            style: { gridSize: 4, display: true },
            belongsToGroup: true,
          },
          {
            label: "Name",
            type: "string",
            style: { gridSize: 4, display: true },
            key: "submission.familyHead.name",
          },
          {
            label: "Contact Number",
            type: "string",
            key: "submission.familyHead.contact_number",
            style: { gridSize: 4, display: true },
          },
          {
            label: "Relation",
            type: "string",
            key: "submission.familyHead.relation",
            style: { gridSize: 4, display: true },
          },
        ],
      },
    ],
  },
];
