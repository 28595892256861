import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

export const documentConfig = {
  config: (mapper) => [
    {
      tabId: 2,
      sectionId: 1,
      actions: [
        {
          gridSize: 12,
          classes: "btn btn-primary btn-transition-none d-inline view-button",

          type: "form",
          constraints: {
            display: {
              conditions: [
                {
                  key: "name",
                  checkValue: "Hospital",
                  operator: "some",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  key: "is_reviewed",
                  checkValue: false,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.attachment.is_reviewed,
                },
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.is_assigned,
                },
                {
                  key: "id",
                  checkValue: mapper.currentUser?.id,
                  operator: "find",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.taskUsers,
                },
              ],
              conditionsEquation: "$0 && $1 && $2 && $3",
            },
          },
        },
        {
          gridSize: 3,
          label: "View File",
          classes: "btn btn-primary btn-transition-none d-inline view-button",
          onClick: {
            functionName: "viewFile",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
            ],
          },
          constraints: {
            display: {
              conditions: [
                {
                  key: "is_reviewed",
                  checkValue: false,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.attachment.is_reviewed,
                },
                {
                  key: "name",
                  checkValue: "Hospital",
                  operator: "some",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.is_assigned,
                },
              ],
              conditionsEquation: "!($0 && $1 && $2)",
            },
          },
        },
        {
          gridSize: 3,
          label: "View Comment",
          classes: "btn btn-primary btn-transition-none d-inline view-button ml-2",
          onClick: {
            functionName: "viewComment",
            functionAttributes: [
              { field: "string", source: "functionParameters" },
            ],
          },
          constraints: {
            display: {
              conditions: [
                {
                  key: "is_reviewed",
                  checkValue: false,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.attachment.is_reviewed,
                },
                {
                  key: "name",
                  checkValue: "Hospital",
                  operator: "some",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.is_assigned,
                },
              ],
              conditionsEquation: "!($0 && $1 && $2)",
            },
          },
        },
        {
          gridSize: 3,
          label: "View History",
          classes:
            "btn btn-primary btn-transition-none d-inline view-button ml-2",
          onClick: {
            functionName: "viewHistory",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
            ],
          },
          constraints: {
            display: {
              conditions: [
                {
                  key: "name",
                  checkValue: ["Hospital", "Inspector", "Inspection Manager"],
                  operator: "compare2Arrays",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
              ],
              conditionsEquation: "!$0 ",
            },
          },
        },
        {
          classes: "review-icon ",
          label: "",
          icon: CheckIcon,
          conditionalClasses: [
            { className: "green", field: "is_reviewed", value: true },
            {
              className: "light-green",
              field: "is_reviewed",
              value: false,
            },
          ],
          onClick: {
            functionName: "handleReviewAttachments",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
              { field: "approve", source: "static", value: true },
            ],
          },
          constraints: {
            display: {
              conditions: [
                {
                  key: "name",
                  checkValue: "Hospital",
                  operator: "some",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  key: "name",
                  checkValue: mapper.attachment.reviewers,
                  operator: "compare2Arrays",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
              ],
              conditionsEquation: "!$0 && $1",
            },
            disable: {
              conditions: [
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.is_assigned,
                },
                {
                  key: "id",
                  checkValue: mapper.currentUser.id,
                  operator: "find",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.taskUsers,
                },
              ],
              conditionsEquation: "!($0 && $1)",
            },
          },
        },
        {
          classes: "review-icon ",
          conditionalClasses: [
            {
              className: "light-red",
              field: "is_reviewed",
              value: true,
            },
            { className: "red", field: "is_reviewed", value: false },
          ],
          onClick: {
            functionName: "handleReviewAttachments",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
              { field: "approve", value: false, source: "static" },
            ],
          },
          label: "",
          icon: ClearIcon,
          constraints: {
            display: {
              conditions: [
                {
                  key: "name",
                  checkValue: "Hospital",
                  operator: "some",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  key: "name",
                  checkValue: mapper.attachment.reviewers,
                  operator: "compare2Arrays",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
              ],
              conditionsEquation: "!$0 && $1",
            },
            disable: {
              conditions: [
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.is_assigned,
                },
                {
                  key: "id",
                  checkValue: mapper.currentUser.id,
                  operator: "find",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.taskUsers,
                },
              ],
              conditionsEquation: "!($0 && $1)",
            },
          },
        },
      ],
    },
    {
      tabId: 3,
      sectionId: 1,
      actions: [
        {
          gridSize: 12,
          classes: "btn btn-primary btn-transition-none d-inline view-button",

          type: "form",
          constraints: {
            display: {
              conditions: [
                {
                  key: "name",
                  checkValue: "Hospital",
                  operator: "some",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  key: "is_reviewed",
                  checkValue: false,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.attachment.is_reviewed,
                },
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.is_assigned,
                },
                {
                  key: "id",
                  checkValue: mapper.currentUser?.id,
                  operator: "find",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.taskUsers,
                },
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.submission.values.is_email,
                },
              ],
              conditionsEquation: "$0 && $1 && $2 && $3 && $4",
            },
          },
        },
        {
          gridSize: 3,
          label: "View File",
          classes: "btn btn-primary btn-transition-none d-inline view-button",
          onClick: {
            functionName: "viewFile",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
            ],
          },
          constraints: {
            display: {
              conditions: [
                {
                  key: "is_reviewed",
                  checkValue: false,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.attachment.is_reviewed,
                },
                {
                  key: "name",
                  checkValue: "Hospital",
                  operator: "some",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.is_assigned,
                },
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.submission.values.is_email,
                },
              ],
              conditionsEquation: "!($0 && $1 && $2 && $3)",
            },
          },
        },
        {
          gridSize: 3,
          label: "View History",
          classes:
            "btn btn-primary btn-transition-none d-inline view-button ml-2",
          onClick: {
            functionName: "viewHistory",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
            ],
          },
          constraints: {
            display: {
              conditions: [
                {
                  key: "name",
                  checkValue: ["Hospital", "Inspector", "Inspection Manager"],
                  operator: "compare2Arrays",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
              ],
              conditionsEquation: "!$0 ",
            },
          },
        },
        {
          classes: "review-icon ",
          label: "",
          icon: CheckIcon,
          conditionalClasses: [
            { className: "green", field: "is_reviewed", value: true },
            {
              className: "light-green",
              field: "is_reviewed",
              value: false,
            },
          ],
          onClick: {
            functionName: "handleReviewAttachments",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
              { field: "approve", source: "static", value: true },
            ],
          },
          constraints: {
            display: {
              conditions: [
                {
                  key: "name",
                  checkValue: "Hospital",
                  operator: "some",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  key: "name",
                  checkValue: mapper.attachment.reviewers,
                  operator: "compare2Arrays",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.submission.values.is_email,
                },
              ],
              conditionsEquation: "!$0 && $1 && $2",
            },
            disable: {
              conditions: [
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.is_assigned,
                },
                {
                  key: "id",
                  checkValue: mapper.currentUser.id,
                  operator: "find",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.taskUsers,
                },
              ],
              conditionsEquation: "!($0 && $1)",
            },
          },
        },
        {
          classes: "review-icon ",
          conditionalClasses: [
            {
              className: "light-red",
              field: "is_reviewed",
              value: true,
            },
            { className: "red", field: "is_reviewed", value: false },
          ],
          onClick: {
            functionName: "handleReviewAttachments",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
              { field: "approve", value: false, source: "static" },
            ],
          },
          label: "",
          icon: ClearIcon,
          constraints: {
            display: {
              conditions: [
                {
                  key: "name",
                  checkValue: "Hospital",
                  operator: "some",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  key: "name",
                  checkValue: mapper.attachment.reviewers,
                  operator: "compare2Arrays",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.currentUser.roles,
                },
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.submission.values.is_email,
                },
              ],
              conditionsEquation: "!$0 && $1 && $2",
            },
            disable: {
              conditions: [
                {
                  checkValue: true,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: mapper.task.is_assigned,
                },
                {
                  key: "id",
                  checkValue: mapper.currentUser.id,
                  operator: "find",
                  functionName: "applyArrayMethods",
                  actualValue: mapper.taskUsers,
                },

              ],
              conditionsEquation: "!($0 && $1)",
            },
          },
        },
      ],
    },
    // {
    //   tabId: 3,
    //   sectionId: 1,
    //   actions: [
    //     {
    //       gridSize: 4,
    //       label: "View File",
    //       classes: "btn btn-primary btn-transition-none d-inline view-button",
    //       onClick: {
    //         functionName: "viewFile",
    //         functionAttributes: [
    //           { field: "file", source: "functionParameters" },
    //         ],
    //       },
    //     },
    //     {
    //       gridSize: 4,
    //       label: "View History",
    //       classes:
    //         "btn btn-primary btn-transition-none d-inline view-button ml-2",
    //       onClick: {
    //         functionName: "viewHistory",
    //         functionAttributes: [
    //           { field: "file", source: "functionParameters" },
    //         ],
    //       },
    //       constraints: {
    //         display: {
    //           conditions: [
    //             {
    //               key: "name",
    //               checkValue: ["Hospital", "Inspector", "Inspection Manager"],
    //               operator: "compare2Arrays",
    //               functionName: "applyArrayMethods",
    //               actualValue: mapper.currentUser.roles,
    //             },
    //           ],
    //           conditionsEquation: "!$0",
    //         },
    //       },
    //     },
    //   ],
    // },

    {
      tabId: 4,
      sectionId: 1,
      actions: [
        {
          gridSize: 4,
          label: "View File",
          classes: "btn btn-primary btn-transition-none d-inline view-button",
          onClick: {
            functionName: "viewFile",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
            ],
          },
        },
        {
          gridSize: 4,
          label: "View History",
          classes:
            "btn btn-primary btn-transition-none d-inline view-button ml-2",
          onClick: {
            functionName: "viewHistory",
            functionAttributes: [
              { field: "file", source: "functionParameters" },
            ],
          },
        },
      ],
    },
  ],
};
