import React from 'react'
import {
    Button,
    Grid,
    Input
  } from "@material-ui/core";
  import {CloudUpload,Done} from "@material-ui/icons";
import { getValue } from 'utils/GetObjectValues';

const Signature = ({formik,setImages,progress, rowName,name}) => {
  return (
    <>
    <Grid item sm={6}>
    {rowName}:
  </Grid>
  <Grid item sm={6} style={{ display: "contents" }}>
      <Input
        accept="image/*"
        style={{display:"none"}}
        id="contained-button-file-arabic"
        type="file"
        value=""
        name={name}
        onChange={(e) => {
          let file = e.target.files[0];
          if (file?.type === "application/pdf") {
            formik.setFieldValue(`${name}`, file, false);
            formik.setFieldError(
              "signatureArabic",
              "File must be image"
            );
          } else {
            setImages(e);
          }
        }}
      />
        <Grid item sm={6} >
      <label htmlFor="contained-button-file-arabic">
        <Button
          size="medium"
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUpload />}
        >
          {rowName}
        </Button>
      </label>
      </Grid>
      {getValue(formik.values, `${name}`) && (
        <div
          className={
            getValue(formik.errors, `${name}`)
              ? "text-danger"
              : "text-success"
          }
        >
          {getValue(formik.errors, `${name}`)
            ? getValue(formik.errors, `${name}`)
            : getValue(formik.values, `${name}`) && (
               progress !== 100 ? <>
               <span> {progress} </span> 
               </>
               :
               <Done />
              )}
        </div>
      )}
  </Grid>
  </>
  )
}

export default Signature