import React, { useEffect, useState } from "react";
import { Grid, Select, MenuItem, Input } from "@material-ui/core";
import eventsFunctions from "../utils/EventsFunctions";
const EditInput = (props) => {
  let [input, setInput] = useState([]);
  useEffect(() => {
    if (props) {
      if (props?.onInitFunction) {
        let result = eventsFunctions[props.onInitFunction](props);
        setInput({ ...props, [result.property]: result.value });
      } else {
        setInput(props);
      }
    }
  }, [props]);
  return (
    <>
      <Grid item sm={6}>
        {input.title}
      </Grid>
      <Grid item sm={6}>
        {input.menuItems ? (
          <Select
            value={input.value || ""}
            onChange={input.onChange}
            style={{
              width: "100%",
              overflow: "hidden",
            }}
            disabled={input.disable}
          >
            {console.log("render input", input)}
            {input.menuItems.map((menuItem) => (
              <MenuItem key={menuItem.id} value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            ))}
          </Select>
        ) : !input.title?.includes("Password", 0) ? (
          <Input
            type="text"
            value={input.value || ""}
            onChange={input.onChange}
            style={{
              width: "100%",
              overflow: "hidden",
            }}
            inputProps={{
              disabled: input.disable,
            }}
          />
        ) : (
          <Input
            type="password"
            value={input.value || ""}
            onChange={input.onChange}
            autoComplete="off"
            name={input.name}
            style={{
              width: "100%",
              overflow: "hidden",
            }}
            inputProps={{
              disabled: input.disable,
            }}
          />
        )}
      </Grid>
    </>
  );
};

export default EditInput;
