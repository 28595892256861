import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik, FormikProvider, yupToFormErrors } from "formik";
import * as Yup from "yup";
import imag from "../../assets/images/Checklist.jpg";
import Swal from "sweetalert2";
import "./index.scss";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import BlockUi from "react-block-ui";
import { SyncLoader } from "react-spinners";
import { RenderStepper } from "../Stepper/index";
import { connect } from "react-redux";
import {
  enableSubmitButton,
  resetForm,
  saveDraft,
  startEngine,
  submitUpdateRequestTask,
  updateSubmission,
} from "reducers/form/formActions";
import client from "api/apiAuth/guestClient";
import _ from "lodash";
import sanitizeObject from "utils/SanitizeObject";
import {
  createRandomId,
  setInputKey,
  setSections,
  ConstructFormErrors,
  determineDisplayingForSections,
  nestedExistenceOfOneKey,
} from "./utils";
import { GetStepContent } from "./getStepContent";
import { getAllowedSubmissionCount } from "reducers/settings/settingsActions";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
const {
  messages,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/allMesaages`);

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(15),
    marginRight: theme.spacing(1),
    border: "2px solid primary",
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      border: "2px solid #0066cc",
    },
  },

  actionsContainer: {
    marginBottom: theme.spacing(4),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

// Render each input according to its widget type
const FormSteps = (props) => {
  const {
    form,
    formId,
    validationSchema,
    submit,
    id,
    isRenew,
    draftValues,
    submissionValues,
    history,
    initialValues,
    setInitialValues,
    companyAllowedSubmissionCount,
    getAllowedSubmissionCount,
    user,
    draftValidationSchema,
    requestsNo,
    inputEvents,
    submissionId,
    config,
    submitUpdateTask,
    manyEntities,
    setManyEntities,
    resetForm,
  } = props;

  const [progress, setProgress] = useState([]);
  const [comment, setComment] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [subActiveStep, setSubActiveStep] = useState(0);
  const [childStep, setChildStep] = useState(false);
  const [steps, setSteps] = useState([]);
  const [sectionNameFlags, setSectionNameFlags] = useState({});
  const [numbering, setNumbering] = useState({
    sectionIndex: 1,
    childSectionIndex: 1,
  });
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  let [blocking, setBlocking] = useState(false);
  const [yupSchema, setYupSchema] = useState(
    Yup.object().shape(validationSchema)
  );
  const [elementsProtoType, setElementsProtoType] = useState({});
  const [rejectionReason, setRejectionReason] = useState("");
  const _isMounted = useRef(true); // Initial value _isMounted = true
  const displaySectionObj = useRef(true);

  const classes = useStyles();
  let formik;
  const context = { user };
  formik = useFormik({
    validate: async (values, props) => {
      try {
        await yupSchema.validate(values, {
          abortEarly: false,
          context: { ...context, ...values },
        });
      } catch (err) {
        delete err.value;
        //console.log({ err });
        return yupToFormErrors(err);
      }
    },
    validateOnChange: false,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  useEffect(() => {
    setYupSchema(Yup.object().shape(validationSchema));
  }, [validationSchema]);
  const toggle = () => setRejectModalOpen(!rejectModalOpen);
  const setProtoTypeInitialValues = (obj, initialValues, sections) => {
    sections.forEach((section, i) => {
      const isSubSections = [section.group, section.step].some((e) =>
        Array.isArray(e)
      );
      Object.entries(initialValues).forEach(([key, value]) => {
        if (typeof value === "object") {
          if (section.key === key) {
            if (Array.isArray(value)) {
              if (key === "submissions") {
                obj[section.name] = value.map((e, i) => {
                  formik.setFieldValue(
                    setInputKey(
                      {
                        multi_key: "submissions.[i].randomId",
                        key: "randomId",
                      },
                      [i]
                    ),
                    e.file_code
                  );
                  return {
                    randomId: e.file_code,
                  };
                });
              } else
                obj[section.name] = Array.from(
                  { length: value.length },
                  () => ({})
                );
              if (isSubSections) {
                obj[section.name].forEach((ele, i) => {
                  setProtoTypeInitialValues(obj[section.name][i], value[i], [
                    ...(section.group || []),
                    ...(section.step || []),
                  ]);
                });
              }
            } else {
              if (key === "submission") {
                formik.setFieldValue(
                  setInputKey({
                    multi_key: "submissions.[i].randomId",
                    key: "randomId",
                  }),
                  value.file_code
                );
                obj[section.name] = {
                  randomId: value.file_code,
                };
              } else obj[section.name] = {};
              setProtoTypeInitialValues(obj[section.name], value, [
                ...(section.group || []),
                ...(section.step || []),
              ]);
            }
          } else if (section.key === "self") {
            obj[section.name] = {};
            setProtoTypeInitialValues(obj[section.name], initialValues, [
              ...(section.group || []),
              ...(section.step || []),
            ]);
          }
        }
      });
    });
  };

  const setObjInArr = (obj, sections, randomId) => {
    sections.forEach((section) => {
      const isSubSections = [section.group, section.step].some((e) =>
        Array.isArray(e)
      );
      if (section.isMany) {
        const addedObj = {};
        if (section.key === "submissions") addedObj.randomId = randomId;
        obj[section.name] = [addedObj];

        if (isSubSections)
          obj[section.name].forEach((ele) =>
            setObjInArr(ele, [
              ...(section.group || []),
              ...(section.step || []),
            ])
          );
      } else {
        const addedObj = {};
        if (section.key === "submission") addedObj.randomId = randomId;
        obj[section.name] = addedObj;
        isSubSections &&
          setObjInArr(
            obj[section.name],
            [...(section.group || []), ...(section.step || [])],
            randomId
          );
      }
    });
  };

  let protoTypeObj = {};
  const setProtoType = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (key !== "randomId") {
        protoTypeObj[key] = Array.isArray(obj[key]) ? obj[key][0] : obj[key];
        setProtoType(protoTypeObj[key]);
      }
    });
    setElementsProtoType(protoTypeObj);
  };
  useEffect(() => {
    let sections = _.cloneDeep(form),
      obj = {};
    if (
      _.isEmpty(initialValues) ||
      (!_.isEmpty(initialValues) && !initialValues.submissions) ||
      submissionId
    ) {
      const randomId = formik.values.submission?.randomId || createRandomId();
      if (!config?.continue?.sub_id) {
        if (submissionId)
          formik.setFieldValue(
            setInputKey({
              multi_key: "submissions.[i].randomId",
              key: "submission.randomId",
            }),
            initialValues.file_code
          );
        else {
          const multiSubmissions = form.find(
            (sec) => sec.isMany && sec.key === "submissions"
          );
          formik.setFieldValue(
            setInputKey(
              {
                multi_key: "submissions.[i].randomId",
                key: "submission.randomId",
              },
              multiSubmissions ? [0] : undefined
            ),
            randomId
          );
        }
      }
      setObjInArr(obj, sections, randomId);
      setProtoType(obj);
    } else {
      setProtoTypeInitialValues(obj, initialValues, form);
    }
    //FIXME: update setManyEntities state in specific events
    _.isEmpty(manyEntities) && setManyEntities(obj);
  }, [form, initialValues]);

  useEffect(() => {
    _isMounted.current = true;
    getAllowedSubmissionCount({ user_id: user.id });

    return () => {
      _isMounted.current = false;
      resetForm();
    };
  }, []);

  useEffect(() => {
    if (draftValues && id && !isRenew) setInitialValues(draftValues);
  }, [draftValues]);

  useEffect(() => {
    if (!_.isEmpty(submissionValues) && !draftValues)
      setInitialValues(submissionValues);
  }, [submissionValues, draftValues]);

  useEffect(() => {
    if (steps.length && !_.isEmpty(initialValues)) {
      const newSteps = _.cloneDeep(steps);
      for (const step of newSteps) {
        const currentStepName = step?.key;
        if (initialValues?.hasOwnProperty(currentStepName)) {
          step.number = initialValues[currentStepName]?.length;
        }
      }
      setSteps(newSteps);
    }
  }, [initialValues]);

  useEffect(() => {
    if ("onManyEntityChangeEvents" in config)
      for (const ev of config.onManyEntityChangeEvents)
        ev({ manyEntities, setManyEntities });
  }, [manyEntities]);

  useEffect(() => {
    if (form && !_.isEmpty(form)) {
      setSections(form, _isMounted, setSteps, inputEvents, draftValues, user);
    }
    displaySectionObj.current = determineDisplayingForSections(form);
  }, [form]);

  let onValueChange = null;
  if ("onValueChangeEvents" in config)
    onValueChange = (input, newValue, event) => {
      for (const valueEvent of config.onValueChangeEvents)
        valueEvent(input, newValue, event, formik);
    };

  let onSectionAdd = null;
  if ("onAddSectionEvents" in config)
    onSectionAdd = (section, cloneManyEntities, setManyEntities) => {
      for (const sectionAddEvent of config.onAddSectionEvents)
        sectionAddEvent({
          section,
          cloneManyEntities,
          formik,
          setManyEntities,
          yupSchema,
        });
    };

  let onSectionRemove = null;
  if ("onRemoveSectionEvents" in config)
    onSectionRemove = (section, cloneManyEntities, setManyEntities) => {
      for (const sectionRemoveEvent of config.onRemoveSectionEvents)
        sectionRemoveEvent({
          section,
          cloneManyEntities,
          formik,
          setManyEntities,
        });
    };

  const handleNext = async (e, sections) => {
    window.scrollTo(0, 0);
    e.stopPropagation();
    if (activeStep >= sections.length - 1) {
      let errorsObject = false;
      errorsObject = await formik.validateForm();

      if (!_.isEmpty(errorsObject)) {
        enableSubmitButton();
        Swal.fire({
          title: "The following fields are either required or invalid:",
          html: `${ConstructFormErrors(errorsObject).join("<br><br>")}`,
          icon: "error",
          confirmButtonColor: "#d36467",
          confirmButtonText: "Try Again",
          width: "50%",
        });
      } else {
        handleSubmit();
      }
      return;
    }

    if (sections[activeStep].step) {
      if (subActiveStep === sections[activeStep].step.length - 1) {
        handelActiveStep(activeStep + 1, 0, false);
      } else {
        handelActiveStep(activeStep, subActiveStep + 1, true);
      }
    } else {
      handelActiveStep(activeStep + 1, 0, false);
    }
  };

  const handleBack = (sections) => {
    window.scrollTo(0, 0);
    if (sections[activeStep]?.step) {
      if (subActiveStep === 0) {
        handelActiveStep(activeStep - 1, 0, false);
      } else {
        handelActiveStep(activeStep, subActiveStep - 1, true);
      }
    } else {
      handelActiveStep(activeStep - 1, 0, false);
    }
  };

  const handleSubmit = () => {
    if (requestsNo > 0) {
      Swal.fire({
        title: "Please wait ",
        text: `Please wait while some files are uploaded`,
        icon: "info",
        confirmButtonColor: "#d36467",
        dangerMode: true,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure you want to submit your application? ",
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        let { attachments, ...values } = formik.values;
        let SubmissionData = sanitizeObject(values);
        let is_email = SubmissionData?.submission.is_email;
        SubmissionData?.submission.is_email &&
          delete SubmissionData?.submission.is_email;
        if (
          SubmissionData?.submission.is_assigned ||
          SubmissionData?.submission.assigned_user_id
        ) {
          delete SubmissionData?.submission.is_assigned;
          delete SubmissionData?.submission.assigned_user_id;
        }

        if (config && "modification" in config) {
          if (!config.modification.submissionId) {
            Swal.fire({
              title: "Please enter a submission id",
              html: "",
              confirmButtonText: "Ok",
            });
            return;
          }
          async function submitModification() {
            const response = await client.post("workflow/modifySubmission", {
              submission_id: config.modification.submissionId,
              modification_id: config.modification.modificationId,
              data: SubmissionData,
            });
            Swal.fire({
              title: "Your modification has been sent successfully!",
              html: "",
              imageUrl: imag,
              imageWidth: 250,
              imageHeight: 200,
              imageAlt: "Custom image",
              confirmButtonColor: "#0066cc",
              confirmButtonText: "Ok",
            }).then(() => history.push("/"));
          }
          submitModification();
        } else {
          (submissionId
            ? submitUpdateTask({
                ...SubmissionData,
                form_id: parseInt(formId),
                sub_id: config?.continue?.sub_id,
              })
            : submit({
                ...SubmissionData,
                form_id: parseInt(formId),
                isRenew,
                is_email,
                uniqueId: id,
                is_draft: false,
                sub_id: config?.continue?.sub_id,
              })
          )
            .then((res) => {
              // setActiveStep(0);
              // setSubActiveStep(0);

              Swal.fire({
                title: "Your application has been sent successfully!",
                html: messages.submitMessageFun(res.value.data.duration),
                imageUrl: imag,
                imageWidth: 250,
                imageHeight: 200,
                imageAlt: "Custom image",
                confirmButtonColor: "#0066cc",
                confirmButtonText: "Ok",
              }).then(() => history.push("/"));
            })
            .catch((err) => {
              Swal.fire("There is something went wrong", "error");
              console.log("err", err);
            });
        }
      }
    });
  };

  const handleEmailAssignment = async () => {
    Swal.fire({
      title: "Do you want to assign yourself  this request?",
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      titleClass: "fontSize",
      customClass: "swal-wide",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        const response = client
          .post(`submission/updateSubmissionValues`, {
            submission_id: draftValues.submission.id,
            submission_edit: {
              assigned_user_id: user.id,
              is_assigned: true,
            },
          })
          .then((res) => {
            Swal.fire({
              title: "Assigned Successfully",
              imageAlt: "Custom image",
              confirmButtonColor: "#0066cc",
              confirmButtonText: "Ok",
            }).then(() => {
              window.location.reload();
            });
          });
      }
    });
  };

  const handleRejection = async () => {
    setRejectModalOpen(true);
  };

  const handleSendEmail = async (event) => {
    setBlocking(true);
    const response = await client
      .get("email/rejectionEmail", {
        params: {
          submissionId: draftValues.submission.id,
          rejectionReason,
        },
      })
      .then((response) => {
        setBlocking(false);
        Swal.fire({
          title: "Sent Successfully",
          confirmButtonColor: "#0066cc",
          confirmButtonText: "Ok",
        }).then(() => {
          history.push("/");
        });
      })
      .catch((err) => {
        console.log(err);
        setBlocking(false);
      });
  };

  const handleDraft = async () => {
    try {
      const draftValues = sanitizeObject(formik.values);

      if (nestedExistenceOfOneKey(draftValues, "submission", "randomId")) {
        Swal.fire("You have to fill data to be saved in the draft");
        return;
      }
      Swal.fire({
        title: "Are you sure you want to save the application as a draft? ",
        showConfirmButton: true,
        titleHeight: 200,
        showCancelButton: true,
        confirmButtonClass: "submit",
        cancelButtonClass: "cancel",
        titleClass: "fontSize",
        customClass: "swal-wide",
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        closeOnConfirm: false,
      }).then(async function ({ isConfirmed }) {
        if (isConfirmed) {
          try {
            const valid = await Yup.object()
              .shape(draftValidationSchema)
              .validate(draftValues);
            if (!_.isEmpty(valid)) {
              submit({
                ...draftValues,
                form_id: parseInt(formId),
                isRenew,
                is_draft: true,
                uniqueId: id,
              })
                .then((res) => {
                  Swal.fire({
                    title: "Succeeded",
                    text: "Your application has been saved as a draft successfully",
                    icon: "success",
                    timer: 3000,
                  }).then(() => history.push("/"));
                })
                .catch((err) => {
                  console.log("err", err);
                  history.push("/");
                });
            }
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Invalid Data",
              text: `Please review submitted data and try again`,
              icon: "error",
              confirmButtonColor: "#d36467",
              confirmButtonText: "Try Again",
              dangerMode: true,
            });
          }
        }
      });
    } catch (err) {
      Swal.fire("There is something went wrong", "error");
      console.log(err);
    }
  };
  const handelActiveStep = (stepValue, subStepValue, type) => {
    setActiveStep(stepValue);
    setSubActiveStep(subStepValue);
    setChildStep(type);
    setNumbering({
      sectionIndex: stepValue + 1,
    });
  };

  return (
    <Grid container className="root bg-color-gray">
      <FormikProvider value={formik}>
        <Grid>
          <Dialog
            open={rejectModalOpen}
            onClose={toggle}
            classes={{ paper: "shadow-sm-dark rounded" }}
          >
            <BlockUi
              className="block-loading-overlay-light"
              tag="div"
              blocking={blocking}
              loader={
                <SyncLoader color="#4f55a28f" loading={blocking} keepInView />
              }
            >
              <DialogContent>
                <DialogContentText>
                  <h5>Please enter the reason of rejection</h5>
                </DialogContentText>
                <textarea
                  rows="10"
                  cols="40"
                  onChange={(e) => {
                    setRejectionReason(e.target.value);
                  }}
                ></textarea>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="success"
                  disabled={rejectionReason ? false : true}
                  className="save-btn"
                  onClick={(e) => handleSendEmail(e)}
                >
                  Send Email
                </Button>
              </DialogActions>
            </BlockUi>
          </Dialog>
        </Grid>
        <Grid item sm={2} md={2} className="stepper">
          <RenderStepper
            steps={steps}
            activeStep={activeStep}
            subActiveStep={subActiveStep}
            handelActiveStep={handelActiveStep}
          ></RenderStepper>
        </Grid>
        {steps.length > 0 && (
          <Grid
            item
            sm={10}
            md={10}
            container
            className="form-style pt-0 pr-4 pl-4"
          >
            {!submissionId ? (
              config && "showDraftButton" in config ? (
                config.showDraftButton && (
                  <Grid item xs={12} className="mt-2">
                    {!draftValues?.submission?.is_email &&
                      !(
                        draftValues?.submission.is_assigned &&
                        draftValues?.submission.assigned_user_id == user.id
                      ) && (
                        <Button
                          variant="outlined"
                          color="primary"
                          className="save-btn mr-4 float-right"
                          onClick={(e) => handleDraft()}
                        >
                          <InsertDriveFileOutlinedIcon />
                          {draftValues?.submission?.is_email
                            ? "Save"
                            : "Save as draft"}
                        </Button>
                      )}
                    {!draftValues?.submission.is_assigned &&
                    draftValues?.submission?.is_email ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        className="save-btn mr-4 float-right"
                        onClick={() => handleEmailAssignment()}
                      >
                        <InsertDriveFileOutlinedIcon />
                        Assign To Myself
                      </Button>
                    ) : null}
                  </Grid>
                )
              ) : (
                <Grid item xs={12} className="mt-2">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="save-btn mr-4 float-right"
                    onClick={(e) => handleDraft()}
                  >
                    <InsertDriveFileOutlinedIcon />
                    Save as draft
                  </Button>
                </Grid>
              )
            ) : (
              <></>
            )}

            <Grid item xs={12}>
              {GetStepContent({
                formik,
                activeStep,
                steps,
                subActiveStep,
                setInitialValues,
                childStep,
                numbering,
                manyEntities,
                setManyEntities,
                elementsProtoType,
                companyAllowedSubmissionCount,
                submissionId,
                sectionNameFlags,
                setSectionNameFlags,
                onValueChange,
                comment,
                setComment,
                onSectionAdd,
                onSectionRemove,
                config,
                context,
                progress,
                setProgress,
                displaySectionObj: displaySectionObj.current,
              })}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.actionsContainer}>
                <Grid container className="m-2" spacing={1}>
                  {activeStep !== 0 && (
                    <Grid item xs={2} md={2} lg={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleBack(steps)}
                        className={`${classes.button} float-right`}
                      >
                        <div className="mr-1">
                          <b>Back</b>
                        </div>
                      </Button>
                    </Grid>
                  )}

                  {activeStep === steps.length - 1 &&
                  draftValues?.submission?.is_email ? (
                    <Grid item xs={2} md={2} lg={2} className="text-left ">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: "#d32f2f" }}
                        onClick={(e) => handleRejection(e)}
                        className={`${classes.button} float-left `}
                        disabled={
                          draftValues && draftValues.submission?.is_email
                            ? !(
                                draftValues.submission.is_assigned &&
                                draftValues.submission.assigned_user_id ==
                                  user.id
                              )
                            : false
                        }
                      >
                        <b>Reject</b>
                      </Button>
                    </Grid>
                  ) : null}

                  <Grid item xs={2} md={2} lg={2} className="text-left">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleNext(e, steps)}
                      className={`${classes.button} float-left`}
                      disabled={
                        draftValues && draftValues.submission?.is_email
                          ? !(
                              draftValues.submission.is_assigned &&
                              draftValues.submission.assigned_user_id == user.id
                            )
                          : false
                      }
                    >
                      <b>
                        {activeStep === steps.length - 1 ? "Submit" : "Next"}
                      </b>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
      </FormikProvider>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    submission: state.form.submission,
    draftValues: state.form.draftValues,
    submissionValues: state.form.submissionValues,
    enableSubmit: state.form.enableSubmit,
    companyAllowedSubmissionCount:
      state.settings.settings.companyAllowedSubmissionCount,
    user: state.auth.user,
    requestsNo: state.general.requestsNo,
    inputEvents: state.form.inputEvents,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    submit: (submission) => dispatch(startEngine(submission)),
    submitUpdateTask: (submission) =>
      dispatch(submitUpdateRequestTask(submission)),

    saveDraft: (submission) => dispatch(saveDraft(submission)),
    enableSubmitButton: () => dispatch(enableSubmitButton()),
    updateSubmission: (submission) => dispatch(updateSubmission(submission)),
    getAllowedSubmissionCount: (params) =>
      dispatch(getAllowedSubmissionCount(params)),

    resetForm: () => dispatch(resetForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSteps);
