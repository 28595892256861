import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import clsx from "clsx";
import "./style.scss";
import FilterModal from "components/filterComponents/FilterModal";
import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchWorklist } from "reducers/list";
import client from "api/apiAuth/guestClient";
const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    width: "93%",
    flex: 1,
    boxShadow: "none !important",
  },
  // iconButton: {
  //   padding: 10,
  // },
}));
const WorklistFilter = (props) => {
  let { filterLayout, currentTab, getWorklist, tasks, formId} = props;
  const classes = useStyles();
  const FilterAltOutlinedIcon = createSvgIcon(
    <path
      d="M20 3H4a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L9 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 13 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L5 6.59V5h14.001l.002 1.583l-5.71 5.71z"
      fill="currentColor"
    />,
    "FilterAltOutlinedIcon"
  );
  const FilterAltOffOutlinedIcon = createSvgIcon(
    <path
      d="M 0.1695 1.0063 C 0.4607 0.3918 1.0798 0 1.76 0 H 20.768 C 21.45 0 22.066 0.3918 22.3564 1.0063 C 22.6512 1.621 22.5588 2.3483 22.0924 2.8745 L 17.4416 8.6064 C 13.9128 9.3324 11.2244 12.452 11.2244 16.192 C 11.2244 17.3976 11.5412 18.5416 12.034 19.558 C 11.9592 19.5228 11.8932 19.4788 11.8272 19.4304 L 9.0112 17.3184 C 8.6548 17.0544 8.448 16.6364 8.448 16.192 V 12.7116 L 0.3978 2.8745 C -0.0328 2.3483 -0.1217 1.621 0.1696 1.0063 H 0.1695 z M 12.6324 16.192 C 12.6324 12.694 15.51 9.856 19.008 9.856 C 22.506 9.856 25.344 12.694 25.344 16.192 C 25.344 19.69 22.506 22.528 19.008 22.528 C 15.51 22.528 12.6324 19.69 12.6324 16.192 z M 21.6172 14.5772 C 21.8944 14.3044 21.8944 13.8556 21.6172 13.5828 C 21.3444 13.3056 20.8956 13.3056 20.6228 13.5828 L 19.008 15.1976 L 17.3932 13.5828 C 17.1204 13.3056 16.6716 13.3056 16.3988 13.5828 C 16.1216 13.8556 16.1216 14.3044 16.3988 14.5772 L 18.0136 16.192 L 16.3988 17.8068 C 16.1216 18.0796 16.1216 18.5284 16.3988 18.8012 C 16.6716 19.0784 17.1204 19.0784 17.3932 18.8012 L 19.008 17.1864 L 20.6228 18.8012 C 20.8956 19.0784 21.3444 19.0784 21.6172 18.8012 C 21.8944 18.5284 21.8944 18.0796 21.6172 17.8068 L 20.0024 16.192 L 21.6172 14.5772 z"
      fill="currentColor"
    />,
    "FilterAltOffOutlinedIcon"
  );
  const [openModal, setOpenModal] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [filters, setFilters] = useState([])
  const openStepInputs = () => {
    setOpenModal(true);
  };
  useEffect(() => {
    if (tasks?.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [tasks]);
  const setSearchValue = (value) => {
    let filterObject = [];
    filterLayout = filterLayout.flat();
    let searchfield = filterLayout.filter((item) => item.search);
    if (value ) {
      let values = searchfield.map((field) => {
        if(field.operator === "like"){
        return {
          field: field.key,
           operator: field.operator,
          value: `%${value}%`,
          json: field.json,
        }
      }else{
        return {
          field: field.key,
           operator: field.operator,
          value: value,
          json: field.json,
        }
      }
      });
      filterObject = [
        {
          operator: "or",

          value: values,
        },
      ];
    }

    setSearchData({ filters: filterObject });
  };
  
  let searchfield = filterLayout.flat().filter((item) => item.search);
  const names = searchfield.map((field) => field.name);
  const applySearch = (event) => {
    event.preventDefault();
    getWorklist({ ...searchData, currentTab, form_id: formId })
  };
  const clear_Filter = () => {
        getWorklist({ ...searchData, currentTab, form_id: formId });
        setFilters([]);
      };
      const removeFromFilter = (item) => {
        const newFilterData = filters.filter((filter) => filter.field !== item.key);
        setFilters(newFilterData);
      };
      const removeAllFilter = () => {
        setFilters([]);
      };
  return (
    <Grid
      container
      className="mt-1 mb-1"
      spacing={2}
      style={{ borderRadius: "0 20px 0 0" }}
    >
      <Grid item md={6} className="mt-1 ml-3">
        <div className={clsx("d-flex align-items-center w-100 search-bar-div")}>
          <Paper
            style={{ boxShadow: "none " }}
            component="form"
            className="w-100 search-bar"
          >
            <InputBase
              className={classes.input}
              placeholder={names.join(",")}
              inputProps={{ "aria-label": "search..." }}
              onChange={(event) => {
                event.persist();
                setSearchValue(event.target.value);
              }}
            />
            <IconButton
              type="submit"
              className={` p-0`}
              aria-label="search"
              onClick={(e) => applySearch(e)}
            >
              <SearchTwoToneIcon />
            </IconButton>
          </Paper>
        </div>
      </Grid>

      <Grid item md={5} className=" ml-5">
        <div className="d-flex align-end flex-wrap">
          <Button
            onClick={openStepInputs}
            className="m-2 btn-transparent search-btn btn-outlined"
          >
            <FilterAltOutlinedIcon className=" filter-icon" />
            <span className="filter-txt ml-1"> Filter Results</span>
          </Button>
          {/* <Button
            onClick={handleRefresh}
            className="m-2 btn-transparent search-btn btn-outlined"
          >
            <FilterAltOutlinedIcon className=" filter-icon" />
            <span className="filter-txt ml-1"> Refresh results</span>
          </Button> */}
          <Button
            onClick={clear_Filter}
            className="m-2 btn-transparent search-btn btn-outlined"
          >
            <FilterAltOffOutlinedIcon className=" filter-icon" />
            <span className="filter-txt ml-1"> Clear filter </span>
          </Button>
        </div>
      </Grid>

      {openModal && (
        <FilterModal
          open={openModal}
          setOpen={setOpenModal}
          filterLayout={filterLayout}
          currentTab={currentTab}
          filters={filters}
          setFilters={setFilters}
          removeFromFilter={removeFromFilter}
          removeAllFilter={removeAllFilter}
        />
      )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    modelData: state.filterData.modelData,
    tasks: state.list.tasks,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWorklist: (params) => dispatch(fetchWorklist(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorklistFilter);
