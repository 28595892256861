import React, { useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";
import "../../style.scss";
import { getConditionalClasses, handleClick } from "pages/EdaDetails/utils";
import InputWidget from "components/FormSteps/InputWidget";
import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";
import { useState } from "react";
import FileHistory from "../FileHistory";
import AttachmentComment from "../AttachmentComment";

const {
  documentConfig,
} = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/document`);
const {
  config
} = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/FormStepsConfig`);
function Document(props) {
  const {
    attachment,
    setFile,
    setOpen,
    allAttachments,
    setAllAttachments,
    attachmentsUnderReview,
    setAttachmentReviewed,
    selectedTab,
    currentUser,
    task,
    sectionId,
    formik,
  } = props;

  const [actions, setActions] = useState([]);
  const [progress, setProgress] = useState([]);
  const [openFileHistory, setOpenFileHistory] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [fileHistory, setFileHistory] = useState({});
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  useEffect(() => {
    const mapper = {
      attachment: attachment,
      currentUser,
      taskUsers: task.users,
      task,
    };
    const tab = documentConfig
      .config(mapper)
      .find(
        (tab) => tab.tabId === selectedTab.id && tab.sectionId == sectionId
      );
    setActions(
      tab?.actions.map((action) => {
        if (action.constraints) {
          return {
            ...action,
            constraints: applyDetailsConstraints(action.constraints),
          };
        } else {
          return { ...action, constraints: { display: true, disable: false } };
        }
      })
    );
    setComment(attachment.comment)
  }, [attachment]);

  let onValueChange = null;
  if ("onValueChangeEvents" in config)
    onValueChange = (input, newValue, event) => {
      for (const valueEvent of config.onValueChangeEvents)
        valueEvent(input, newValue, event, formik);
    };

  const actionProperties = {
    ...props,
    viewFile: ({ file }) => {
      setOpen(true);
      setFile(file);
    },
    viewComment: ({ comment }) => {
      console.log(comment)
      setOpenCommentModal(true);
      // setComment(comment);
    },
    viewHistory: ({ file }) => {
      setOpenFileHistory(true);
      setFileHistory(file);
    },
    handleReviewAttachments: ({ file, approve }) => {
      let submissionAttachments = _.cloneDeep(allAttachments) || [];
      let newReviewedAttachments = _.cloneDeep(attachmentsUnderReview) || [];
      submissionAttachments.forEach((attachment) => {
        if (file.id === attachment.id) {
          attachment.is_reviewed = approve;
          let attachmentIndex = attachmentsUnderReview.findIndex(
            (reviewedAttachment) => reviewedAttachment.id === file.id
          );
          setAllAttachments(
            submissionAttachments.map((uploadedFile) => {
              if (uploadedFile.id === file.id) {
                uploadedFile.is_reviewed = approve;
                uploadedFile.is_dirty = true;
              }
              return uploadedFile;
            })
          );
          if (attachmentIndex > -1) {
            newReviewedAttachments[attachmentIndex] = {
              ...attachment,
              is_dirty: true,
            };
            setAttachmentReviewed([...newReviewedAttachments]);
          } else {
            setAttachmentReviewed([
              ...attachmentsUnderReview,
              { ...attachment, is_dirty: true },
            ]);
          }
          return;
        }
      });
    },
  };
  const getValue = (obj, path, inputType) => {
    if (path) {
      path = path.replace(/\[(\w+)\]/g, "$1"); // convert indexepaths to propertis
      path = path.replace(/^\./, ""); // strip a leading dot
      let value = path.split(".").reduce((o, i) => {
        if (o) {
          if (o[i] === true || o[i] === false) return o[i].toString();
          if (inputType === "date") {
            return o[i] && typeof o[i] !== "object"
              ? new Date(o[i]).toLocaleDateString("en-CA")
              : o[i];
          }
          return o[i];
        }
        return null;
      }, obj);
      return value;
    }
  };
  const input = {
    ...attachment.document,
    key: "document",
    display:true
  }
  const submissionValue = task.submission
  const inputValue =  submissionValue?.values?.is_email ? (getValue(formik.values, input.equivalent_key, input.itype))?.[0] : getValue(formik.values, input.key, input.itype) ; 
  return (
    <div>
      <Grid container spacing={0} className="mb-4 ">
        {attachment?.document?.name_en && (
          <Grid item md={12} sx={6} className="documents">
            <div className={"doc-header"}>{submissionValue.values.is_email ? inputValue?.original_file_name || attachment.original_file_name : attachment.document.name_en}</div>
          </Grid>
        )}
        <Grid item md={12} className="documents mt-2">
          {actions?.map((action, index) => {
            let ButtonIcon = action.icon;
            return (
              <React.Fragment key={index}>
                {action.constraints?.display &&
                  (action.type !== "form" ? (
                    <Button
                      className={`${action.classes} ${getConditionalClasses(
                        attachment,
                        action.conditionalClasses
                      )} mr-3`}
                      disableFocusRipple
                      disableRipple
                      disabled={action.constraints.disable}
                      onClick={(e) =>
                        handleClick(e, action, actionProperties, {
                          file: attachment,
                          comment:attachment.comment
                        })
                      }
                    >
                      {action.icon && <ButtonIcon />}
                      {action.label}
                    </Button>
                  ) : task.submission.values.is_email ? 
                  (
                    <InputWidget
                      formik={formik}
                      progress = {progress}
                      setProgress = {setProgress}
                      comment={comments}
                      onValueChange={onValueChange}
                      setComment={setComments}
                      index = {attachment.index}
                      input={{
                        ...attachment.document,
                        key: "document",
                        display:true
                      }}
                      submissionValue={task.submission}
                    />
                  ):(
                    <InputWidget
                      formik={formik}
                      progress = {progress}
                      index = {attachment.index}
                      comment={comments}
                      setComment={setComments}
                      onValueChange={onValueChange}
                      setProgress = {setProgress}
                      input={{
                        ...attachment.document,
                        key: attachment.document.equivalent_key,
                        display:true
                      }}
                      submissionValue={task.submission}
                    />
                  ))}
              </React.Fragment>
            );
          })}
            {/* <div>
              {attachment.comment}
            </div> */}
          {openFileHistory && (
            <FileHistory
              openFileHistory={openFileHistory}
              setOpenFileHistory={setOpenFileHistory}
              fileHistory={fileHistory}
            />
          )}
          {openCommentModal && (
            <AttachmentComment
            openCommentModal={openCommentModal}
            setOpenCommentModal={setOpenCommentModal}
              comment={comment}
              attachment={attachment}
              currentUser={currentUser}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    task: state.details.task,
  };
};

export default connect(mapStateToProps)(Document);
