import {
  Card,
  Grid,

} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PausePresentationIcon from "@material-ui/icons/PausePresentation";
import PeopleIcon from "@material-ui/icons/People";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";

const Cards = ({ cardsDetails }) => {
  const [allCards, setAllCards] = useState([]);
  const icons = {
    AssignmentTurnedInIcon,
    DynamicFeedIcon,
    AssignmentLateIcon,
    CancelPresentationIcon,
    AssignmentIcon,
    PausePresentationIcon,
    PeopleIcon,
    LabelImportantIcon,
  };

  useEffect(() => {
    if (cardsDetails) setAllCards(cardsDetails);
  }, [cardsDetails]);

  return (
    <div className="mb-spacing-6">
      <Grid container spacing={6}>
        {allCards?.map((card, index) => {
          let Icon = icons[card.icon];
          return (
            <Grid item xl={3} md={6} key={index}>
              <Card
                className="card-box border-0 shadow-first-sm p-4"
                style={{ backgroundColor: card.backgroundColor }}
              >
                <div className="d-flex align-items-center">
                  <div
                    className="d-40 btn-icon rounded-circle  text-white text-center font-size-lg mr-3"
                    style={{ backgroundColor: card.color }}
                  >
                    <Icon />
                  </div>
                  <div className="text-black-50 mr-2">{card.cardTitle}</div>
                </div>
                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                  <div>{card.count}</div>
                </div>
              </Card>
            </Grid>
          );
        })}
        <hr className="mt-4" />
      </Grid>
    </div>
  );
};
export default Cards;
