import client from "api/apiAuth/guestClient";
import _ from "lodash";
import {
  getInitialEvents,
  getOnInitInitialValue,
} from "utils/FormUtils/Events/EventsHelperFunctions";
import { getValue } from "utils/GetObjectValues";
import { applyInputConstraints } from "utils/LayoutConstraints/ConstraintsService";
import { substituteByActualIndex } from "utils/ValidationSchema/helperFunctions";
const updateInputs = async (inputs, eventInputs, draftValues, user) => {
  return await Promise.all(
    inputs.map(async (input) => {
      const eventInput = eventInputs.find((ele) => ele.id === input.id);

      let result = await getInitialEvents(eventInput);
      if (result) {
        return result;
      }
      if (
        draftValues &&
        draftValues.submission.is_email &&
        !(
          draftValues.submission.is_assigned &&
          draftValues.submission.assigned_user_id == user.id
        )
      ) {
        input.disabled = true;
      }
      return input;
    })
  );
};
export const setSections = async (
  form,
  mount,
  setSteps,
  eventInputs,
  draftValues,
  user
) => {
  let sections = _.cloneDeep(form);
  sections = await Promise.all(
    sections.map(async (section) => {
      if (section?.group) {
        section.group = await Promise.all(
          section.group.map(async (group) => {
            group.inputs = await updateInputs(
              group.inputs,
              eventInputs,
              draftValues,
              user
            );
            return group;
          })
        );
      }
      if (section?.step) {
        section.step = await Promise.all(
          section.step.map(async (step) => {
            step.inputs = await updateInputs(
              step.inputs,
              eventInputs,
              draftValues,
              user
            );
            return step;
          })
        );
      }
      section.inputs = await updateInputs(
        section.inputs,
        eventInputs,
        draftValues,
        user
      );
      return section;
    })
  );
  mount.current && setSteps(sections);
};

export function disableInputs(
  ev,
  mainInput,
  option,
  step,
  subActiveStepIndex,
  formik,
  SetInputKey,
  // setSubmission,
  submission
) {
  const isChildSection = step.child_sections.length > 0;
  const [changedInput] = findChangedInput(
    step,
    mainInput.id,
    subActiveStepIndex,
    isChildSection
  );
  if (!changedInput) return [null, null];
  if (
    !changedInput.validatedInputs.length &&
    !changedInput.validatedDocuments.length
  )
    return [null, null];
  clearValues(
    changedInput,
    formik,
    SetInputKey,
    isChildSection,
    // setSubmission,
    option,
    step,
    submission
  );
  /*return evaluateDisabled(
    changedInput,
    ev,
    step,
    subActiveStepIndex,
    isChildSection,
    changedInputIndex
  );*/
}

function findChangedInput(step, inputId, subActiveStepIndex, isChildSection) {
  if (isChildSection) {
    for (
      let i = 0;
      i < step.child_sections[subActiveStepIndex].inputs.length;
      i++
    ) {
      const input = step.child_sections[subActiveStepIndex].inputs[i];
      if (input.id === inputId) return [input, i];
    }
  } else {
    for (let i = 0; i < step.inputs.length; i++) {
      const input = step.inputs[i];
      if (input.id === inputId) return [input, i];
    }
  }
  return [null, null];
}

function clearValues(
  input,
  formik,
  SetInputKey,
  isChildSection,
  // setSubmission,
  option,
  step,
  submission
) {
  if (input.validatedInputs.length && isChildSection) {
    input.validatedInputs.forEach((validatedInput) => {
      if (
        input.input_layout.type === "checkbox" &&
        option.label !==
          validatedInput.InputValidation.condition?.value.replace(/[('')]/g, "")
      ) {
        return;
      }
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, "");
    });
  }

  if (input.validatedDocuments.length && isChildSection) {
    input.validatedDocuments.forEach((validatedInput) => {
      if (
        input.input_layout.type === "checkbox" &&
        option.label !==
          validatedInput.DocumentValidation.condition?.value.replace(
            /[('')]/g,
            ""
          )
      ) {
        return;
      }
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, "");
    });
  }

  if (input.validatedInputs.length && !isChildSection) {
    input.validatedInputs.forEach((validatedInput) => {
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, "");
    });
  }

  if (input.validatedDocuments.length && !isChildSection) {
    input.validatedDocuments.forEach((validatedInput) => {
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, "");
      // setSubmission({
      //   ...submission,
      //   attachments: submission.attachments.filter(
      //     (attachment) => attachment.name !== inputKey
      //   ),
      // });
    });
  }
}

export const getEventInputs = (inputEvents, type) =>
  inputEvents.filter((e) => e.events.some((ev) => ev.trigger === type));

export function uploadFile(
  input,
  file,
  formik,
  documentName,
  isMultiple,
  uniqueNo
) {
  let formData = new FormData();
  formData.append("avatar", file);
  client
    .post(
      `attachment/uploadFile?documentName=${input.equivalent_key}&submission_id=${uniqueNo}`,
      formData
    )
    .then((result) => {
      const documentData = {
        ...result.data.results,
        input_id: input.id,
        original_file_name: file.name,
        fileObject: file,
      };
      let inputValue = getValue(formik.values, input.key, input.itype);
      if (inputValue?.id) {
        documentData.id = inputValue.id;
      }

      formik.setFieldValue(isMultiple ? documentName : input.key, documentData);
      setTimeout(() => {
        formik.setFieldTouched(documentName, true, true).then((err) => {});
      }, 0);
    })
    .catch((error) => {
      console.log(error);
      formik.setFieldValue(input.key, "");
    });
}

export function findBySectionId(id, steps) {
  for (const step of steps) {
    if (step.id === id) return step;
    if (step.group) {
      const result = findBySectionId(id, step.group);
      if (result) return result;
    }
  }
  return null;
}

export function setInputKey(input, numParts) {
  let key = input.key;
  if (typeof numParts != "undefined") {
    key = input.multi_key;
    numParts.forEach((element) => {
      key = key.replace("[i]", element);
    });
  }
  return key;
}

export function addSiblingChild(section, manyEntities, steps, randomId) {
  function findSiblingSection(mainSection) {
    if (mainSection.group) {
      for (const childSection of mainSection.group) {
        if (childSection.main_section_id) return childSection;
        if (childSection.group) {
          const result = findSiblingSection(childSection);
          if (result) return result;
        }
      }
    }
    return null;
  }

  const siblingSection = findSiblingSection(section);

  if (!siblingSection) return;
  const otherSiblingSection = findBySectionId(
    siblingSection.main_section_id,
    steps
  );
  if (
    otherSiblingSection.name in manyEntities &&
    Array.isArray(manyEntities[otherSiblingSection.name])
  ) {
    const arrayLen = manyEntities[section.name].length;

    manyEntities[section.name][arrayLen - 1][siblingSection.name] = _.cloneDeep(
      manyEntities[section.name][0][siblingSection.name]
    );
  }
}
export function getMultiSectionKeyOfInput(input) {
  const parts = input.multi_key.split(".");
  const inputPartIdx = parts.indexOf(input.key);
  // As the previous part would be the [i] "multi instances indicator"
  const sectionPart = parts[inputPartIdx - 2];
  return sectionPart;
}

export function findInputFromSection(section, equivalent_key) {
  return section.inputs.find(
    (input) => input.equivalent_key === equivalent_key
  );
}

export function createRandomId() {
  const { v4: uuidv4 } = require("uuid");
  return uuidv4();
}

export function ConstructFormErrors(errorsObject) {
  errorsObject = flattenObj(errorsObject);
  const submissionsKeyRE = /submissions\[\d+\]/;
  const indexKey = /\d+/;
  const errors = [];
  for (const [key, value] of Object.entries(errorsObject)) {
    const result = key.match(submissionsKeyRE);
    if (result) {
      const index = Number(result[0].match(indexKey));
      errors.push(`${value} in Submission ${index + 1}`);
    } else errors.push(value);
  }
  return errors;
}

export function flattenObj(object) {
  const getEntries = (o, prefix = "") => {
    return Object.entries(o).flatMap(([k, v]) => {
      return Object(v) === v
        ? //FIXME:replace static key "values" with dynamic given keys
          _.isEmpty(v)
          ? [[`${prefix}${k}`, v]]
          : getEntries(v, `${prefix}${k}.`)
        : [[`${prefix}${k}`, v]];
    });
  };
  return Object.fromEntries(getEntries(object));
}

export async function excuteOnInitInitialValuesEvents(
  initialValues,
  inputEvents
) {
  const eventInputs = getEventInputs(inputEvents, "onInitInitialValues");
  for (const eventInput of eventInputs) {
    const key = eventInput.key;
    const keyParts = key.split(".");
    let results = [];
    const calcValueAndExecuteEvent = async (keys, values, arrayIndex) => {
      const key = keys.shift();
      if (keys.length !== 0) {
        if (keys[0] === "[i]") {
          keys.shift();
          const arrayKeys = Array(values[key].length)
            .fill()
            .map(() => [...keys]);
          const arrayIndex = Array(values[key].length)
            .fill()
            .map(() => []);
          for (const [i, value] of values[key]?.entries()) {
            arrayIndex[i].push(i);
            await calcValueAndExecuteEvent(arrayKeys[i], value, arrayIndex[i]);
          }
        } else {
          await calcValueAndExecuteEvent(keys, values[key], arrayIndex);
        }
      } else {
        await getOnInitInitialValue(
          eventInput,
          { values: initialValues },
          values?.[key],
          arrayIndex
        );
      }
    };
    await calcValueAndExecuteEvent(keyParts, initialValues, results);
  }
}

const checkConditionalProps = (arrayIndexes, dependency, isMany, values) => {
  const constraints = dependency.reduce((constraints, element) => {
    let newKey;
    const conditions = element.conditions.map((condition) => {
      newKey = isMany ? condition.key : condition.single_key;
      if (arrayIndexes && !condition.context_key)
        newKey = substituteByActualIndex(arrayIndexes, condition, isMany);
      return {
        ...condition,
        key: newKey,
        actualValue: getValue(values, newKey),
      };
    });

    let result = {
      ...constraints,
      ...applyInputConstraints(
        {
          ...element,
          conditions,
        },
        "layout"
      ),
    };

    // if (
    //   dependency.find((dependent) =>
    //     dependent.constraints?.validation?.hasOwnProperty("dependent")
    //   )
    // ) {
    //   let validation = {
    //     ...constraints,
    //     ...applyInputConstraints(
    //       {
    //         ...element,
    //         conditions,
    //       },
    //       "validation"
    //     ),
    //   };
    //   result = {
    //     ...result,
    //     ...validation,
    //   };
    // }

    if (element.constraints?.enumValues?.length) result.display = true;

    element.conditions.forEach((condition) => {
      let checkedKey = isMany ? condition.key : condition.single_key;
      if (arrayIndexes && !condition.context_key)
        checkedKey = substituteByActualIndex(arrayIndexes, condition, isMany);

      if (
        element.constraints?.enumValues?.length &&
        result.enumValues &&
        condition.checkValue === getValue(values, checkedKey)
      ) {
        result = {
          ...result,
          options: element.constraints?.enumValues.map((option) =>
            typeof option === "object"
              ? option
              : {
                  label: option,
                }
          ),
        };
      }
    });

    return result;
  }, {});

  return constraints;
};

export function calcDisplayInputsArr(inputs) {
  const inputArr = [];
  inputs.forEach((input) =>
    input.dependency.length > 0
      ? inputArr.push(function (arrayIndexes, values, isMany) {
          return checkConditionalProps(
            arrayIndexes,
            input.dependency,
            isMany,
            values
          );
        })
      : inputArr.push({ disable: false, display: true })
  );
  return inputArr;
}

export function determineDisplayingForSections(form) {
  const obj = {};
  const setObjInArr = (obj, sections) => {
    sections?.forEach((section) => {
      const isSubSections = [section.group, section.step].some((e) =>
        Array.isArray(e)
      );
      if (section.isMany) {
        const addedObj = {
          displayInputs: calcDisplayInputsArr(section.inputs),
        };
        obj[section.name] = [addedObj];

        if (isSubSections)
          obj[section.name].forEach((ele) =>
            setObjInArr(ele, [
              ...(section.group || []),
              ...(section.step || []),
            ])
          );
      } else {
        const addedObj = {
          displayInputs: calcDisplayInputsArr(section.inputs),
        };
        obj[section.name] = addedObj;
        isSubSections &&
          setObjInArr(obj[section.name], [
            ...(section.group || []),
            ...(section.step || []),
          ]);
      }
    });
  };
  setObjInArr(obj, form);
  return obj;
}

export function getInputsPropsArray(displayObject, sectionName, isMany) {
  return isMany
    ? displayObject[sectionName][0]?.displayInputs
    : displayObject[sectionName]?.displayInputs;
}

export function calcInputProps(input, values, isMany, arrayIndexes) {
  return typeof input === "object"
    ? input
    : input(arrayIndexes, values, isMany);
}

export function calcDisplaySection(inputsPropsArray) {
  const result = inputsPropsArray?.length
    ? inputsPropsArray.reduce((display, ele) => {
        return display || ele.display;
      }, false)
    : true;
  return result;
}

export function nestedExistenceOfOneKey(draft, ...args) {
  return args.reduce((obj, level) => {
    if (obj) {
      const objKeys = Object.keys(obj);
      const existAndOnly =
        objKeys.length <= 1
          ? Array.isArray(level)
            ? level.includes(objKeys[0])
            : objKeys[0] === level
          : false;
      return existAndOnly && obj[objKeys[0]];
    } else return false;
  }, draft);
}
