import {

  Grid,

} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Doughnut } from "react-chartjs-2";

export default function PieChart({ cardsDetails }) {
  let [pieChartDetails, setPieChartDetails] = useState([]);
  const [allCards, setAllCards] = useState([]);

  useEffect(() => {
    if (allCards) {
      let formatedData = allCards.map((element) => {
        let labels = [];
        let data = [];
        let backgroundColor = [];
        element.count.forEach((i) => {
          let field = Object.keys(i)[0];
          let foundLabel = element?.labels?.find(
            (label) => label.field == field && label.value == i[field]
          );
          if (foundLabel) {
            labels.push(foundLabel.label);
            data.push(i.count);
            backgroundColor.push(element.color[foundLabel.label]);
          }
        });
        return {
          labels,
          paragraphs: element.count,
          piechartLabel: element.piechartLabel,
          datasets: [
            {
              data,
              backgroundColor,
              hoverBackgroundColor: backgroundColor,
            },
          ],
        };
      });
      setPieChartDetails(formatedData);
    }
  }, [allCards]);
  useEffect(() => {
    if (cardsDetails) setAllCards(cardsDetails);
  }, [cardsDetails]);
  return (
    <>
      <Grid container>
        {pieChartDetails.map((data, index) => {
          return (
            <React.Fragment key={index}>
              {data.labels.length>0 && (
                <Grid item md={3}>
                  <Doughnut data={data} />
                  <div style={{textAlign:"center"}}><b>{data.piechartLabel}</b></div>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
      <hr />
    </>
  );
}
