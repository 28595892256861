import _ from "lodash";
import { fetchEntityDataAux1 } from "reducers/list";
import store from "reducers/store";

const eventsFunctions = {
  GetRolesAndEnableInput: (e, tableColumns, setTableColumns) => {
    let oldColumns = _.cloneDeep(tableColumns);
    let columns = oldColumns.map((column) => {
      if (e.target.value && column.key == "roles") {
        column.disable = false;
      } else if (column.key == "roles") {
        column.disable = true;
      }
      return column;
    });
    setTableColumns(columns);
    store.dispatch(
      fetchEntityDataAux1({
        model: "Role",
        where: {
          name: { operator: "not in", value: ["Distributor", "admin"] },
        },
        include: [
          {
            association: "org_types",
            required: true,
            include: [
              {
                association: "orgs",
                required: true,
                where: { name: e.target.value },
              },
            ],
          },
        ],
      })
    );
  },
  EnableFieldOnEdit: (input) => {
    if (input.isEdit) {
      return { property: "disable", value: false };
    } else {
      return { property: "disable", value: input.disable };
    }
  },
};
export default eventsFunctions;
