//0 ---> My Assigned Applications
//1 ----> Not assigned Applications
//2 ----> Ongoing Applications
//3 ----> Completed Applications

const menuObj = {
  worklist: {
    shared: [],

    admin: [
      {
        id: 0,
        name: "My Assigned Applications",
      },
      {
        id: 1,
        name: "Not Assigned Applications",
      },
      {
        id: 2,
        name: "Ongoing Applications",
      },
      {
        id: 3,
        name: "Completed Applications",
      },
    ],

    Hospital: [
      {
        id: 0,
        name: "My Assigned Applications",
      },
      {
        id: 2,
        name: "Ongoing Applications",
      },
      {
        id: 3,
        name: "Completed Applications",
      },
    ],
    "Pre approval reviewer": [
      {
        id: 10,
        name: "My Assigned Email Applications",
      },
      {
        id: 11,
        name: "Not Assigned Email Applications",
      },
      {
        id: 0,
        name: "My Assigned Applications",
      },
      {
        id: 1,
        name: "Not Assigned Applications",
      },
      {
        id: 2,
        name: "Ongoing Applications",
      },
      {
        id: 3,
        name: "Completed Applications",
      },
    ],
    "Patient Affairs manager": [
      {
        id: 0,
        name: "My Assigned Applications",
      },
      {
        id: 1,
        name: "Not Assigned Applications",
      },
      {
        id: 2,
        name: "Ongoing Applications",
      },
      {
        id: 3,
        name: "Completed Applications",
      },
    ],
    "Beneficiary Affairs manger": [
      {
        id: 0,
        name: "My Assigned Applications",
      },
      {
        id: 1,
        name: "Not Assigned Applications",
      },
      {
        id: 2,
        name: "Ongoing Applications",
      },
      {
        id: 3,
        name: "Completed Applications",
      },
    ],
    "C A B D Reviewer": [
      {
        id: 0,
        name: "My Assigned Applications",
      },
      {
        id: 1,
        name: "Not Assigned Applications",
      },
      {
        id: 2,
        name: "Ongoing Applications",
      },
      {
        id: 3,
        name: "Completed Applications",
      },
    ],
    "Committe Member": [
      {
        id: 0,
        name: "My Assigned Applications",
      },
      {
        id: 1,
        name: "Not Assigned Applications",
      },
      {
        id: 2,
        name: "Ongoing Applications",
      },
      {
        id: 3,
        name: "Completed Applications",
      },
    ],
    "Administrator of C A B D": [
      {
        id: 0,
        name: "My Assigned Applications",
      },
      {
        id: 1,
        name: "Not Assigned Applications",
      },
      {
        id: 2,
        name: "Ongoing Applications",
      },
      {
        id: 3,
        name: "Completed Applications",
      },
    ],
    "Head of C A B D (Signer)": [
      {
        id: 0,
        name: "My Assigned Applications",
      },
      {
        id: 1,
        name: "Not Assigned Applications",
      },
      {
        id: 2,
        name: "Ongoing Applications",
      },
      {
        id: 3,
        name: "Completed Applications",
      },
    ],
  },
};
export default menuObj;
