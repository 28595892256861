import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AccordionSummary,
  Accordion,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { fetchSubmissions } from "weflow_configs/newIclaim/components/Inquiry/api";
import { LAYOUT_STRUCTURE } from "./constants";
import Table from "components/Table";
import "./style.css";

function PreviousSubmissionsRelatedToPatient(props) {
  const [data, setData] = useState([]);
  const [layoutStructure, setLayoutStructure] = useState(LAYOUT_STRUCTURE);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const getSubmissions = useCallback(
    async function (submission) {
      if (props.task) {
        console.log(props.task);
        const include = [
          {
            association: "checklist",
            where: {
              "protocol.drug_id": submission.drug.id,
              "protocol.diagnosis_id": submission.diagnosis.id,
            },
          },
          {
            association: "patient",
            where: {
              national_id: submission.patient.national_id,
            },
          },
          { association: "breadwinner" },
        ];
        const filters = [{ field: "id", value: submission.id, operator: "ne" }];
        const { data } = await fetchSubmissions(include, limit, page, filters);
        setData(data);
      }
    },
    [limit, page]
  );

  useEffect(() => {
    console.log("first useeffect", props.task.submission);
    getSubmissions(props.task.submission);
  }, [props.task.submission, getSubmissions]);

  return (
    <div className="accordion mb-spacing-6-x2 w-100">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography variant="h6" style={{ direction: "rtl" }}>
            <span style={{ float: "right" }}>
              طلبات أدوية سابقة خاصة بالمريض
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: "d-block" }}>
          <Table
            className="mt-4 w-100"
            layoutStructure={layoutStructure}
            setLayoutStructure={setLayoutStructure}
            layoutData={data?.rows}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            tasksCount={data?.count || 0}
            page={page}
            propsFunctions={{}}
          ></Table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

PreviousSubmissionsRelatedToPatient.propTypes = { task: PropTypes.any };

export default PreviousSubmissionsRelatedToPatient;
