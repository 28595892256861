import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import Swal from "sweetalert2";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";

import {
  createUserData,
  updateUserData,
  createEntityData,
  updateEntityData,
} from "reducers/list";
import EditInput from "./Components/EditInput";
import ChangePassModal from "./Components/ChangePassModal";
import uploadFile from "pages/AdminPanel/AdminPanel/Utils/uploadFile";
import Signature from "./Components/Signature";
import validationSchema from "pages/AdminPanel/AdminPanel/Utils/validationSchema";
import { useSelector } from "react-redux";
import { getValue } from "utils/GetObjectValues";
import { Done, Clear } from "@material-ui/icons";
import client from "api/apiAuth/guestClient";

import eventsFunctions from "./utils/EventsFunctions";

const EntityModal = (props) => {
  const {
    isEdit,
    entity,
    entityIndex,
    open,
    setModalOpen,
    // eslint-disable-next-line no-unused-vars
    entityDataAux1,
    entityDataAux2,
    entityDataAux3,
    entities,
    auxDataKeys,
    onEntityEdited,
    onEntityCreated,
    model,
    entityName,
    adminColumns,
  } = props;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  const [protocolsDataPerRecord, setProtocolsDataPerRecord] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [validImage, setValidImage] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState({});
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [inputEntity, setInputEntity] = useState({});

  const [tableColumns, setTableColumns] = useState([]);
  const [disable, setDisable] = useState([]);
  const listData = useSelector((state) => state.layout?.columns);
  const checklistColumn = listData?.Admin?.filter((a) => a[entityName])[0]?.[
    entityName
  ]?.checklist;

  let keys = useRef([]);
  let rowName = useRef([]);
  let edit = useRef([]);
  let type = useRef([]);
  let title = useRef([]);
  let data = useRef([]);
  let onChangeFunction = useRef([]);
  let onInitFunction = useRef([]);
  useEffect(() => {
    (async function getProtocolData() {
      if (type?.current.includes("checklist")) {
        const protocols = entities
          ?.map((e, i) => e[checklistColumn])
          ?.flatMap((ek) => (ek ? Object.keys(ek) : null));
        const protocolsKeys = protocols?.filter(
          (item, index) => protocols.indexOf(item) === index
        );

        for (let i = 0; i < protocolsKeys.length; i++) {
          if (protocolsKeys[i]) {
            const data = async () =>
              await client.get("generic/find", {
                params: {
                  model:
                    protocolsKeys[i][0].toUpperCase() +
                    protocolsKeys[i].split("_")[0].slice(1),
                },
              });
            const checklistData = await data();
            setProtocolsDataPerRecord((protocolsDataPerRecord) => [
              ...protocolsDataPerRecord,
              checklistData.data.rows,
            ]);
          }
        }
      }
    })();
  }, [entities, entity]);

  useEffect(() => {
    if (entity) {
      setInitialValues({ ...entity });
      setInputEntity({ ...entity });
    }
  }, [entityDataAux2, open, entity]);

  useEffect(() => {
    _.isEqual(entity, inputEntity) ? setSubmitFlag(false) : setSubmitFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputEntity]);

  useEffect(() => {
    if (validImage) {
      if (!_.isEmpty(file) && entity) {
        uploadFile(file.file, file.fileName, setProgress, dispatch).then(
          (result) => {
            setInputEntity({
              ...entity,
              ...inputEntity,
              [file.fileName]: result.data,
            });
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validImage, file, dispatch]);

  const formik = useFormik({
    validationSchema,
    initialValues,
    validateOnChange: true,
    validationOnBlur: true,
  });
  useEffect(() => {
    if (
      !getValue(formik.errors, "email") &&
      !getValue(formik.errors, "username") &&
      getValue(formik.values, "email") &&
      getValue(formik.values, "username")
    ) {
      setSubmitFlag(true);
    } else {
      setSubmitFlag(false);
    }
  }, [formik.errors, formik.values]);
  const onEditInputChange = (ev, field, all, index, key, checklist) => {
    onChangeFunction.current[index] &&
      eventsFunctions[onChangeFunction.current[index]](
        ev,
        tableColumns,
        setTableColumns
      );
    let entity = [] || {} || "";
    if (all) {
      for (const one of all) {
        if (ev.target.value === one.name) {
          if (field === "roles") {
            entity.push(one);
          } else {
            entity = {};
            checklist ? (entity[checklist] = one.id) : (entity = one);
            break;
          }
        }
      }
    } else {
      // key ? entity[key]= ev.target.value :
      entity = ev.target.value;
    }
    setInputEntity((inputEntity) =>
      key
        ? {
            ...inputEntity,
            [field]: {
              [key]: entity,
            },
          }
        : checklist
        ? {
            ...inputEntity,
            [field]: { ...inputEntity[field], ...entity },
          }
        : {
            ...inputEntity,
            [field]: entity,
          }
    );
    setSubmitFlag(true);
  };

  const handleChangePassword = () => {
    setModalPreviewOpen(true);
  };
  const setImages = (event) => {
    event.persist();
    let fileName = event.target.name,
      file = event.target.files[0];
    var img = document.createElement("img");
    let width, height;
    img.onload = function () {
      file.width = this.width;
      file.height = this.height;
      width = this.width >= 50 && this.width <= 1000;
      height = this.height >= 50 && this.height <= 1000;
      if (width && height) {
        setFile({ file, fileName });
        setValidImage(true);
      } else {
        setSubmitFlag(false);
        setValidImage(false);
      }
      formik.setFieldValue(event.target.name, file);
    };
    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleClose = () => {
    setInputEntity({});
    formik.resetForm();
    setSubmitFlag(false);
    setModalOpen(false);
    setTableColumns(
      adminColumns
        ?.filter((a) => a[entityName])
        .flatMap((A) => A[entityName]?.Columns)
    );
  };

  const handleSubmit = () => {
    try {
      let modelData = {
        ...inputEntity,
      };
      (entityName === "users"
        ? isEdit
          ? dispatch(updateUserData(modelData))
          : dispatch(createUserData(modelData))
        : isEdit
        ? dispatch(
            updateEntityData({ model, modelBody: modelData, keys: auxDataKeys })
          )
        : dispatch(
            createEntityData({ model, modelBody: modelData, keys: auxDataKeys })
          )
      ).then((response) => {
        if (response.value.status === 200) {
          Swal.fire({
            title:
              entityName === "users"
                ? isEdit
                  ? "Update changed"
                  : "User Created"
                : isEdit
                ? "Update changed"
                : `${model} Created`,
            text:
              entityName === "users"
                ? isEdit
                  ? "User saved"
                  : "New User has arrived"
                : isEdit
                ? `${model} saved`
                : `New ${model} has been created`,
            icon: "success",
            confirmButtonColor: "#003655",
            confirmButtonText: "back",
          }).then(function () {
            isEdit
              ? onEntityEdited(entityIndex, inputEntity)
              : entityName === "users"
              ? onEntityCreated(inputEntity, response?.value?.data?.user?.id)
              : onEntityCreated(
                  inputEntity,
                  response?.value?.data?.newInstance?.id
                );
            setInputEntity({});
            formik.resetForm();
            setModalOpen(false);
          });
        } else {
          Swal.fire({
            title:
              entityName === "users"
                ? isEdit
                  ? "Update Failed"
                  : "Could not create a User"
                : isEdit
                ? "Update Failed"
                : `Could not create a ${model}`,
            icon: "error",
            confirmButtonColor: "#d36467",
            confirmButtonText: "Try Again",
          }).then(function () {
            setModalOpen(false);
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (adminColumns)
      setTableColumns(
        adminColumns
          ?.filter((a) => a[entityName])
          .flatMap((A) => A[entityName]?.Columns)
      );
  }, [adminColumns]);

  useEffect(() => {
    if (tableColumns?.length > 0) {
      keys.current = tableColumns.map((column) => column?.["key"]);
      rowName.current = tableColumns.map((column) => column?.["name"]);
      edit.current = tableColumns.map((column) => column?.["edit"]);
      type.current = tableColumns.map((column) => column?.["type"]);
      title.current = tableColumns.map((column) => column?.["title"]);
      data.current = tableColumns.map((column) => column?.["data"]);
      setDisable(tableColumns.map((column) => column?.["disable"]));
      onChangeFunction.current = tableColumns.map(
        (column) => column?.["onChange"]
      );
      onInitFunction.current = tableColumns.map((column) => column?.["onInit"]);
    }
  }, [tableColumns]);

  //   setInputEntity((inputEntity) => ({
  //     ...inputEntity,
  //     [field]: entity,
  //   }));
  //   setSubmitFlag(true);
  // };
  function renderAdminTable(
    key,
    type,
    title,
    i,
    listing,
    entdata,
    name,
    disable,
    onInitFunction
  ) {
    if (listing)
      switch (type) {
        case "array":
          return (
            <EditInput
              key={i}
              title={`${name}:`}
              value={
                inputEntity[key]?.length > 0 ? inputEntity[key][0]?.[title] : ""
              }
              onChange={(ev) => {
                onEditInputChange(ev, `${key}`, props[entdata], i);
              }}
              menuItems={props[entdata]}
              disable={disable}
              onInitFunction={onInitFunction}
              isEdit={isEdit}
            />
          );
        case "object":
          return (
            <EditInput
              key={i}
              title={`${name}:`}
              value={inputEntity[key]?.[title] || ""}
              onChange={(ev) => {
                onEditInputChange(ev, `${key}`, props[entdata], i);
              }}
              menuItems={props[entdata]}
              disable={disable}
              onInitFunction={onInitFunction}
              isEdit={isEdit}
            />
          );
        case "signature":
          return (
            <Signature
              key={i}
              formik={formik}
              setImages={setImages}
              progress={progress}
              rowName={name}
              name={key}
              disable={disable}
            />
          );
        case "password":
          return (
            <ChangePassModal
              key={i}
              open={modalPreviewOpen}
              setOpen={setModalPreviewOpen}
              handleChangePassword={handleChangePassword}
              setSubmitFlag={setSubmitFlag}
              formik={formik}
              setInputEntity={setInputEntity}
              isEdit={isEdit}
              disable={disable}
            />
          );
        case "checklist":
          // const checkListData = param[key]? Object.entries(param[key]) : null;
          const protocols = entities
            ?.map((entity) => entity?.[key])
            ?.flatMap((e) => (e ? Object.keys(e) : []));
          const protocolsKeys = protocols?.filter(
            (item, index) => protocols?.indexOf(item) === index
          );
          const protocolsHeadCleaned = protocolsKeys?.map(
            (header) => header[0].toUpperCase() + header?.split("_")[0].slice(1)
          );
            console.log(data);
          return (
            <React.Fragment key={i}>
              {protocolsKeys?.map((ph, index) => {
                const data = protocolsDataPerRecord[index];
                const selectedOption = protocolsDataPerRecord[index]?.filter(
                  (pd) => pd?.id === inputEntity[key]?.[ph]
                );
                return (
                  <EditInput
                    key={index}
                    title={`${protocolsHeadCleaned[index]}:`}
                    value={
                      selectedOption?.length > 0 ? selectedOption[0]?.name : ""
                    }
                    onChange={(ev) => {
                      onEditInputChange(
                        ev,
                        `${key}`,
                        data,
                        index,
                        props[entdata] ? null : title,
                        ph
                      );
                    }}
                    menuItems={data}
                  />
                );
              })}
            </React.Fragment>
          );
        default:
          return (
            <>
              <EditInput
                key={i}
                title={`${name}:`}
                value={inputEntity?.[key] || ""}
                onChange={(ev) => {
                  onEditInputChange(ev, `${key}`, null, i);
                }}
                disable={disable}
                onInitFunction={onInitFunction}
                isEdit={isEdit}
              />
              {getValue(formik.values, `${inputEntity?.[key]}`) && (
                <div
                  className={
                    getValue(formik.errors, `${inputEntity?.[key]}`)
                      ? "text-danger small"
                      : "text-success small"
                  }
                >
                  {getValue(formik.errors, `${inputEntity?.[key]}`) ? (
                    <>
                      <Clear />
                      {getValue(formik.errors, `${inputEntity?.[key]}`)}
                    </>
                  ) : (
                    <>
                      <Done /> Good To GO!
                    </>
                  )}
                </div>
              )}
            </>
          );
      }
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className="text-center">
        {isEdit ? `Edit ${model}` : `Add ${model}`}
      </DialogTitle>
      <DialogContent className="p-5">
        <Grid container spacing={3}>
          {keys?.current?.map((k, i) =>
            renderAdminTable(
              k,
              type.current[i],
              title.current[i],
              i,
              edit.current[i],
              data.current[i],
              rowName.current[i],
              disable[i],
              onInitFunction.current[i]
            )
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center"
        >
          <Button
            onClick={handleClose}
            color="secondary"
            size="medium"
            variant="contained"
            className="ml-4 mx-4"
            style={{ backgroundColor: "#0b0b0c" }}
          >
            Back
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            size="medium"
            disabled={!submitFlag}
            variant="contained"
          >
            {isEdit ? "Edit" : "Add"}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
const mapStateToProps = (store) => {
  return {
    adminColumns: store.layout.columns.Admin,
  };
};
export default connect(mapStateToProps)(EntityModal);
