import React, { useEffect, useState } from "react";
import client from "../../api/apiAuth/guestClient";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Input,
  Radio,
  RadioGroup,
  Grid,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import { pdfjs } from "react-pdf";
import FileUploader from "components/FileUploader";
import { handleDocument } from "../../utils/uploadFile";
import { uploadFile } from "./utils";
import ErrorMessage from "../ErrorMessage";
import WysiwygEditor from "components/WysiwygEditor";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { format, add } from "date-fns";
import Selection from "components/FormsComponents/Selection";
import BooleanCheckbox from "../FormsComponents/BooleanCheckbox/BooleanCheckbox";
import { getOnchangeEvents } from "../../utils/FormUtils/Events/EventsHelperFunctions";
import AudioRecorder from "../AudioRecorder";
import UploadFile from "components/UploadFile";
import ViewSelect from "components/FormsComponents/ViewSelect";
import { TextareaAutosize } from "@mui/material";

const getValue = (obj, path, inputType) => {
  if (path) {
    path = path.replace(/\[(\w+)\]/g, "$1"); // convert indexepaths to propertis
    path = path.replace(/^\./, ""); // strip a leading dot
    let value = path.split(".").reduce((o, i) => {
      if (o) {
        if (o[i] === true || o[i] === false) return o[i].toString();
        if (inputType === "date") {
          return o[i] && typeof o[i] !== "object"
            ? new Date(o[i]).toLocaleDateString("en-CA")
            : o[i];
        }
        return o[i];
      }
      return null;
    }, obj);
    return value;
  }
};

function InputWidget(props) {
  let {
    input,
    formik,
    enableSubmit,
    submission,
    sectionIndex,
    groupIdx,
    index,
    submissionValue,
    stepId,
    setSectionNameFlags,
    enableOnSubmit,
    onValueChange,
    comment,
    setComment,
    requestsCancelToken,
    uniqueNo,
    arrayIndexes,
    progress,
    setProgress,
  } = props;
  const [internalComment, setInternalComment] = useState("");
  const [onChange, setOnChange] = useState(false);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const inputValue =
    // submissionValue?.values?.is_email
    //   ? getValue(formik.values, input.equivalent_key, input.itype)?.[0]
    getValue(formik.values, input.key, input.itype);
  const inputError = getValue(formik.errors, input.key);
  const inputTouched = getValue(formik.touched, input.key);
  const inputLabel = `${
    sectionIndex !== undefined
      ? ` ${sectionIndex}.${groupIdx ? groupIdx + "." : ""}${index}`
      : ""
  } ${input.name}`;

  const removeValueForHiddenElements = () => {
    if (!input.display) {
      if (input.itype === "document") {
        let fileRequest = requestsCancelToken.find(
          (request) =>
            request.url.includes(
              `attachment/uploadFile?documentName=${input.equivalent_key}`
            ) && request.key === input.key
        );
        if (fileRequest) {
          fileRequest?.token?.cancel(
            `uploading ${input.equivalent_key} file has been canceled  `
          );
        }
      }
    }
    if (!input.display && inputValue) {
      formik.setFieldValue(input.key, undefined);
    }
    if (input.options?.length > 0 && inputValue)
      if (
        !(typeof input.options[0] === "object"
          ? input.options.some(
              (op) => op.id === inputValue || op.label === inputValue
            )
          : input.options.includes(inputValue))
      )
        formik.setFieldValue(input.key, undefined);
  };

  (function setInputProperties() {
    removeValueForHiddenElements();
  })();

  useEffect(() => {
    setSectionNameFlags &&
      setSectionNameFlags((previousState) => {
        return {
          ...previousState,
          [stepId]: {
            ...previousState[stepId],
            [input.id]: input.display,
          },
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  if ("display" in input && !input.display && inputValue) {
    formik.setFieldValue(input.key, undefined);
  }

  const inputs = {
    view_select: () => {
      return (
        <>
          <ViewSelect
            input={input}
            formik={formik}
            inputValue={inputValue}
            onValueChange={onValueChange}
            arrayIndexes={arrayIndexes}
          />
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={enableSubmit || enableOnSubmit}
            errorMessage={inputError}
          />
        </>
      );
    },
    string: () => (
      <>
        <React.Fragment>
          {input.name && input.input_layout.displayLabel !== "false" && (
            <span
              className={
                "heading-4 d-block mt-4 comment  input-label-font text-left"
              }
              style={{
                display: "block",
                ...input.input_layout?.labelStyle,
              }}
            >
              {inputLabel}
              {/* {sectionIndex}.{groupIdx}.{index} {input.name} */}
              {input.required && <span className="color-red">*</span>}
            </span>
          )}
          <FormControl fullWidth>
            <Input
              fullWidth
              name={input.key}
              id={input.key}
              value={inputValue || ""}
              className="input-border"
              disableUnderline
              placeholder={
                input.input_layout && input.input_layout.placeholder
                  ? input.input_layout.placeholder
                  : ""
              }
              inputProps={{
                className: `input ${input.disabled && "disabled"}`,
                disabled: input.disabled,
                "data-section": input.section,
              }}
              onChange={(e) => {
                if (onValueChange) {
                  onValueChange(input, e.target.value, e);
                }
                formik.handleChange(e);
              }}
              onBlur={async (e) => {
                formik.handleBlur(e);
                if (e.target.value) {
                  await getOnchangeEvents(
                    input,
                    formik,
                    e.target.value,
                    arrayIndexes
                  );
                }
              }}
            />
            <ErrorMessage
              isTouched={inputTouched}
              enableSubmit={enableSubmit || enableOnSubmit}
              errorMessage={inputError}
            />
          </FormControl>
        </React.Fragment>
      </>
    ),
    stringOutLine: () => (
      <>
        <React.Fragment>
          {input.name && input.input_layout.displayLabel !== "false" && (
            <span
              className={"p-1 heading-4 input-label-font text-align-left"}
              style={{
                display: "block",
                ...input.input_layout?.labelStyle,
              }}
            >
              {inputLabel}
              {input.required && <span className="color-red">*</span>}
            </span>
          )}
          <FormControl fullWidth>
            <TextField
              size="small"
              fullWidth
              name={input.key}
              id={input.key}
              value={inputValue || ""}
              onChange={(ev) => {
                if (onValueChange) {
                  onValueChange(input, ev.target.value, ev);
                }
                formik.handleChange(ev);
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
              }}
              inputProps={{
                className: `input ${input.disabled && "disabled"}`,
                disabled: input.disabled,
                "data-section": input.section,
              }}
              label={input.name}
              style={{ display: input.disabled ? "none" : "block" }}
              variant="outlined"
            />
            <ErrorMessage
              isTouched={inputTouched}
              enableSubmit={enableSubmit}
              errorMessage={inputError}
            />
          </FormControl>
        </React.Fragment>
      </>
    ),
    dateInspection: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={input.input_layout?.labelStyle}
          >
            {inputLabel}

            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <DatePicker
          autoOk
          variant="static"
          openTo="date"
          name={input.key}
          id={input.key}
          value={inputValue || null}
          minDate={new Date(input?.validation?.min).toString()}
          onChange={(val) => {
            if (onValueChange) {
              onValueChange(
                input,
                new Date(val).toLocaleDateString("en-CA"),
                null
              );
            }
            formik
              .setFieldValue(
                input.key,
                new Date(val).toLocaleDateString("en-CA")
              )
              .then(() => formik.handleBlur({ target: { name: input.key } }));
          }}
          onBlur={(e) => {
            formik.handleBlur(e);
          }}
        />

        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    number: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{ display: "block", ...input.input_layout?.labelStyle }}
          >
            {inputLabel}

            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <FormControl fullWidth>
          <Input
            fullWidth
            name={input.key}
            id={input.key}
            className="input-border"
            value={inputValue || ""}
            type="number"
            disableUnderline
            inputProps={{
              className: `input ${input.disabled && "disabled"}`,
              min: 0,
              disabled: input.disabled,
              "data-section": input.section,
              placeholder: input.input_layout.placeholder,
            }}
            // style={{ display: input.disabled ? "none" : "block" }}
            onChange={(ev, value) => {
              if (onValueChange) {
                onValueChange(input, ev.target.value, ev);
              }
              formik.handleChange({
                ...ev,
                target: {
                  name: ev.target.name,
                  value: ev.target.value || undefined,
                },
              });
            }}
            onBlur={formik.handleBlur}
          />
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={enableSubmit}
            errorMessage={inputError}
          />
        </FormControl>
      </>
    ),
    numberOutLine: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={"p-1 heading-4 input-label-font text-left"}
            style={{ display: "block", ...input.input_layout?.labelStyle }}
          >
            {inputLabel}
            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <FormControl fullWidth>
          <TextField
            size="small"
            fullWidth
            name={input.key}
            id={input.key}
            value={inputValue || ""}
            onChange={(ev, value) => {
              if (onValueChange) {
                onValueChange(input, ev.target.value, ev);
              }
              formik.handleChange(ev, value);
            }}
            onBlur={formik.handleBlur}
            inputProps={{
              className: `input ${input.disabled && "disabled"}`,
              min: 0,
              disabled: input.disabled,
              "data-section": input.section,
            }}
            label={input.name}
            style={{ display: input.disabled ? "none" : "block" }}
            type="number"
            variant="outlined"
          />
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={enableSubmit}
            errorMessage={inputError}
          />
        </FormControl>
      </>
    ),
    date: () => (
      <FormControl fullWidth>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={input.input_layout?.labelStyle}
          >
            {inputLabel}

            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <KeyboardDatePicker
          size="small"
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          emptyLabel="dd/mm/yyyy"
          disableFuture
          maxDate={new Date()}
          maxDateMessage="Date of birth cannot be a future date."
          openTo="date"
          name={input.key}
          id={input.key}
          disabled={input.disabled}
          value={inputValue || null}
          minDate={new Date(input?.validation?.min).toString()}
          onChange={(val) => {
            if (onValueChange) {
              onValueChange(
                input,
                new Date(val).toLocaleDateString("en-CA"),
                null
              );
            }
            formik
              .setFieldValue(
                input.key,
                new Date(val).toLocaleDateString("en-CA")
              )
              .then(() => formik.handleBlur({ target: { name: input.key } }));
          }}
          onBlur={(e) => {
            formik.handleBlur(e);
          }}
        />

        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </FormControl>
    ),
    radioSelect: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{ display: "block", ...input.input_layout?.labelStyle }}
          >
            {inputLabel}
            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <RadioGroup
          className={"radio-select"}
          row
          name={input.key}
          id={input.key}
          data-section={input.section}
          value={inputValue || 0}
          onChange={(ev, value) => {
            const newValue =
              input.itype === "boolean" ? value === "true" : value;
            if (onValueChange) {
              onValueChange(input, newValue, ev);
            }
            formik.handleChange({
              ...ev,
              target: { name: ev.target.name, value: newValue },
            });
          }}
          onBlur={formik.handleBlur}
        >
          {input.itype === "enum"
            ? input.options?.map((option, i) => (
                <FormControlLabel
                  className={
                    inputValue === option.label
                      ? "checkBox-checked"
                      : "checkBox-unchecked"
                  }
                  key={option.id ? option.id : option.label}
                  value={option.label}
                  control={
                    <Radio
                      style={{
                        color: inputValue === option.label ? "#0066cc" : null,
                      }}
                    />
                  }
                  label={option.label}
                  disabled={input.disabled}
                />
              ))
            : [
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ].map((option, i) => (
                <FormControlLabel
                  key={i}
                  className={
                    inputValue === option.value
                      ? "checkBox-checked"
                      : "checkBox-unchecked"
                  }
                  value={option.value}
                  control={
                    <Radio
                      style={{
                        color: inputValue === option.value ? "#0066cc" : null,
                      }}
                    />
                  }
                  label={option.label}
                  disabled={input.disabled}
                />
              ))}
        </RadioGroup>
        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    select: () => (
      <>
        <Selection
          input={input}
          formik={formik}
          inputValue={inputValue}
          loadMore={false}
          onValueChange={onValueChange}
          arrayIndexes={arrayIndexes}
        />
        {(inputTouched || enableSubmit || enableOnSubmit) && inputError ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    selectWithLabel: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{ display: "block", ...input.input_layout?.labelStyle }}
          >
            {inputLabel}
            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <Selection
          input={input}
          formik={formik}
          inputValue={inputValue}
          loadMore={false}
          arrayIndexes={arrayIndexes}
        />
        {(inputTouched || enableSubmit) && inputError ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    selectWithLoadMore: () => (
      <>
        <Selection
          input={input}
          formik={formik}
          inputValue={inputValue}
          loadMore={true}
          onValueChange={onValueChange}
          arrayIndexes={arrayIndexes}
        />
        {(inputTouched || enableSubmit) && inputError ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    document: () => (
      <UploadFile
        input={input}
        formik={formik}
        uniqueNo={uniqueNo}
        submissionValue={submissionValue}
        onValueChange={onValueChange}
        inputValue={inputValue}
        inputError={inputError}
        inputTouched={inputTouched}
        setProgress={setProgress}
        progress={progress}
        index={index}
      />
    ),
    documentWithComments: () => (
      <div
        style={{
          display: "block",
          ...input.input_layout?.labelStyle,
        }}
      >
        <UploadFile
          input={input}
          formik={formik}
          uniqueNo={uniqueNo}
          submissionValue={submissionValue}
          onValueChange={onValueChange}
          inputValue={inputValue}
          inputError={inputError}
          inputTouched={inputTouched}
          setProgress={setProgress}
          progress={progress}
          index={index}
        />
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{
              display: "block",
              ...input.input_layout?.labelStyle,
            }}
          >
            ملاحظات {input.name}
          </span>
        )}
        <FormControl>
          <TextField
            id={`${index}`}
            name={input?.key + ".comment"}
            value={
              comment?.find((c) => c.id === index)?.value && !onChange
                ? comment.find((c) => c.id === index)?.value
                : internalComment?.value
                ? internalComment?.value
                : inputValue?.comment === "undefined"
                ? ""
                : inputValue?.comment
            }
            placeholder="Write your comment...."
            fullWidth
            multiline
            margin="normal"
            InputProps={{ disableUnderline: true }}
            variant="standard"
            minRows={12}
            maxRows={12}
            data-section={input.section}
            onChange={(ev) => {
              setOnChange(true);
              setInternalComment({ id: index, value: ev.target.value });
              console.log(internalComment);
              formik.handleChange(ev);
            }}
            onBlur={async (ev) => {
              // setInternalComment({id:index, value: ev.target.value})
              setOnChange(false);
              let comments = [...comment];
              comments.length !== 0
                ? comments.find((e) => e.id === internalComment.id)
                  ? comments.map((c) =>
                      c.id === internalComment.id
                        ? (c.value = internalComment.value)
                        : null
                    )
                  : comments.push(internalComment)
                : comments.push(internalComment);
              setComment(comments);
              onValueChange(inputValue?.comment, comment[index]?.value, ev);
              await client.post(
                `attachment/addCommentLinkedToAttachment?documentName=${
                  input.equivalent_key
                }&submission_id=${
                  submissionValue?.file_code
                    ? `${submissionValue?.file_code}`
                    : uniqueNo
                }&comment=${inputValue?.comment}`
              );

              formik.handleBlur(ev);
            }}
            style={{
              width: "40vw",
              border: " 2px solid #e4e5eb",
              height: "10vh",
            }}
            disabled={!inputValue?.avatarFd ? true : input.disabled}
          />
          {input.name && input.input_layout.displayLabel !== "false" && (
            <span
              className={
                "heading-4 d-block mt-4 comment  input-label-font text-left"
              }
              style={{
                display: "block",
                ...input.input_layout?.labelStyle,
              }}
            ></span>
          )}
        </FormControl>
      </div>
    ),
    multipleDocument: () => (
      <>
        {input.name && (
          <span
            className={"p-1 heading-4 input-label-font"}
            style={input.input_layout?.labelStyle}
          >
            {input.name}
            {input.required && <span className="color-red"> *</span>}
          </span>
        )}
        <div style={{ alignItems: "center" }}>
          <FileUploader
            submission={submission}
            handleDocument={handleDocument}
            uploadFile={uploadFile}
            input={input}
            inputValue={inputValue}
            formik={formik}
            uniqueNo={uniqueNo}
            submissionValue={submissionValue}

            // setSubmission={setSubmission}
          />
        </div>
        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    checkbox: () => (
      <>
        {input.itype === "boolean" ? (
          <BooleanCheckbox input={input} formik={formik} getValue={getValue} />
        ) : (
          <FormControl
            component="fieldset"
            className="formControl"
            style={input.input_layout?.labelStyle}
          >
            <FormGroup>
              {input.options?.map((option) => {
                return (
                  <React.Fragment key={option.id}>
                    <FormControlLabel
                      className={`mb-3 ${
                        inputValue?.includes(option?.label)
                          ? "checkBox-checked"
                          : "checkBox-unchecked"
                      }`}
                      key={option.id}
                      control={
                        <Checkbox
                          style={
                            inputValue?.includes(option?.label)
                              ? {
                                  color: "#C7A058",
                                }
                              : null
                          }
                          name={input.key}
                          data-section={input.section}
                          onChange={(ev, value) => {
                            if (onValueChange) {
                              onValueChange(input, ev.target.value, value);
                            }
                            formik.handleChange(ev, value);
                          }}
                          onBlur={formik.handleBlur}
                          checked={inputValue?.includes(option.label) || false}
                          value={option.label}
                          disabled={input.disabled}
                        />
                      }
                      label={option.label}
                    />
                  </React.Fragment>
                );
              })}
            </FormGroup>
          </FormControl>
        )}

        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    textEditor: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{
              display: "block",
              ...input.input_layout?.labelStyle,
            }}
          >
            {inputLabel}
            {/* {sectionIndex}.{groupIdx}.{index} {input.name} */}
            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <WysiwygEditor formik={formik} input={input}></WysiwygEditor>

        {(inputTouched || enableSubmit) &&
        getValue(formik.errors, input.key, input.itype) ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    textArea: () => (
      <>
        {input.name && input.input_layout.displayLabel !== "false" && (
          <span
            className={
              "heading-4 d-block mt-4 comment  input-label-font text-left"
            }
            style={{
              display: "block",
              ...input.input_layout?.labelStyle,
            }}
          >
            {inputLabel}
            {/* {sectionIndex}.{groupIdx}.{index} {input.name} */}
            {input.required && <span className="color-red">*</span>}
          </span>
        )}
        <FormControl>
          <textarea
            name={input.key}
            value={inputValue || ""}
            data-section={input.section}
            onChange={(ev) => {
              if (onValueChange) {
                onValueChange(input, ev.target.value, ev);
              }
              formik.handleChange(ev);
            }}
            onBlur={formik.handleBlur}
            rows={input.input_layout.rows || 15}
            cols={"125"}
            style={{
              width: "100%",
              border: " 1px solid #e4e5eb",
              height: "200px",
            }}
            disabled={input.disabled}
          />
        </FormControl>
        {(inputTouched || enableSubmit) &&
        getValue(formik.errors, input.key, input.itype) ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    label: () => (
      <>
        <React.Fragment>
          {inputValue &&
            input.name &&
            input.input_layout.displayLabel !== "false" && (
              <span
                className={"heading-4 mt-4 comment input-label-font text-left"}
                style={{
                  ...input.input_layout?.labelStyle,
                }}
              >
                {` ${input.name}`}{" "}
                {/* {sectionIndex}.{groupIdx}.{index} {input.name} */}
                {input.required && <span className="color-red">*</span>}
              </span>
            )}
          <FormControl fullWidth style={{ display: "inline" }}>
            <p
              className={"heading-4 mt-4 comment  text-left"}
              style={{ display: "inline" }}
            >
              {" "}
              {inputValue}{" "}
            </p>
          </FormControl>
        </React.Fragment>
      </>
    ),
    blank: () => <></>,
    voice: () => {
      async function uploadAudio(data) {
        if (onValueChange) {
          onValueChange(input, data, null);
        }
        const formData = new FormData();
        formData.append(
          "avatar",
          new File([data.blob], "record.webm", {
            type: data.blob.type,
          })
        );

        const result = await client.post(
          `attachment/uploadFile?documentName=${input.equivalent_key}`,
          formData
        );
        if (!input.input_layout.skipFormik)
          formik.setFieldValue(input.key, {
            ...result.data.results,
            input_id: input.id,
            name: input.key,
          });
      }
      return <AudioRecorder onAudioRecord={uploadAudio} />;
    },
  };
  return (
    <React.Fragment key={input.key}>
      {input.display === true && (
        <Grid
          item
          md={input.input_layout?.gridSize}
          lg={input.input_layout?.gridSizelg}
          sm={input.input_layout ? input.input_layout.gridSizeSM : 6}
          xs={12}
          style={input.input_layout || {}}
          className="pl-0"
        >
          <div>{inputs[String(input.input_layout?.type)]()}</div>
        </Grid>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    enableSubmit: state.form.enableSubmit,
    fileUploadError: state.form.fileUploadError,
    submission: state.form.submission,
    user: state.auth.user,
    requestsCancelToken: state.general.requestsCancelToken,
  };
};

export default connect(mapStateToProps, null)(InputWidget);

/*

[submisisons.[i].diagnosis_id ,submission.[i].drug_id]

submisisons.[i].diagnosis_id => value =>
[submissions,[i],[diagnosis]]=key.split(".")
function calcValueOfKey([keys],values){

key= keys.shift()

  if(keys[0]=="[i]") values[key].forEach((value)=>{
    //[diagnsosis_id]
    {disgnosis_id, drug_id,...}
    calcValueOfKey([keys],value)

  })
  else if (keys[0])
    
  else 
    value= object[key]

}


calcValueOfKey(keys.split("."), initialValues)




*/
